import { Item } from '../entities/entities';
import { toast, toastType } from '../services/toastService';

export const copyToClipBoard = (
  item: Item,
  parentFolderId: string,
  projectId: string,
  domainUrl: string,
  linkFormat: string
) => {
  var link = getLink(item, parentFolderId, projectId, domainUrl, linkFormat);
  var textField = document.createElement('textarea');
  textField.value = link;
  textField.setAttribute('readonly', '');
  textField.style.position = 'absolute';
  textField.style.left = '-9999px';
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  document.body.removeChild(textField);
  toast(toastType.success, 'Link copied');
};

export const getLink = (
  item: Item,
  parentFolderId: string,
  projectId: string,
  domainUrl: string,
  linkFormat: string
) => {
  let link = linkFormat.replace('{projectId}', projectId);
  if (item.className === 'Folder') {
    link = link.replace('{folderId}/', item.instanceId);
    link = link.replace('{itemId}', '');
  } else {
    link = link.replace('{folderId}', parentFolderId);
    link = link.replace('{itemId}', item.instanceId);
  }

  return domainUrl + (domainUrl.endsWith('/') ? '' : '/') + link;
};

export const getRedirectPath = (
  projectId: string,
  folderId?: string,
  itemId?: string
) => {
  projectId == 'undefined'? undefined : projectId
  const folderLink = folderId ? `/${folderId}` : '';
  const itemLink = itemId ? `/${itemId}` : '';
  return `${projectId}${folderLink}${itemLink}`;
};
