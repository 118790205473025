import { Item } from '../entities/entities';
import { invalidateData } from './dataActions';
import { defaultShareOptions } from '../services/fetchHelper';
import { getProjectShareUrl } from '../services/utillities';
import { SET_SYNCED_FOLDERS } from './actionConstants';
import { store } from '../store/configureStore';
import { toast, toastType } from '../services/toastService';
import { logger } from '../services/logger';

export const setSyncedFolders = (instances: string[]) => {
  return {
    type: SET_SYNCED_FOLDERS,
    payload: instances,
  };
};

export const isSyncedFolder = (instanceId: string): boolean => {
  const syncFolders = store.getState().syncedFolders;
  return syncFolders.findIndex((item: any) => instanceId.match(item)) !== -1;
};

export const fetchSyncedFolders = () => {
  return async (dispatch: any) => {
    try {
      const url = `${getProjectShareUrl()}/User/?$select=SyncFolder-forward-Folder.*`;
      logger.logInfo(`Started fetching synchronized folders: ${url}`, [url]);
      const response = await fetch(url, { ...defaultShareOptions() });
      const json = await response.json();
      let instanceIds: string[] = [];
      if (
        json &&
        json.instances &&
        json.instances[0] &&
        json.instances[0].relationshipInstances
      ) {
        instanceIds = json.instances[0].relationshipInstances.map(
          (relInstance: any) => {
            return relInstance.relatedInstance.instanceId;
          }
        );
      }
      dispatch(setSyncedFolders(instanceIds));
      logger.logInfo('Fetched synchronized folders successfully');
    } catch (error) {
      logger.logError(`Failed to fetch synchronized folders: ${error}`, [
        error,
      ]);
    }
  };
};

export const getUnSyncFolders = (items: Item[]) => {
  const existingSyncedFolders: string[] = store.getState().syncedFolders;
  return items.filter(
    element => existingSyncedFolders.indexOf(element.instanceId) === -1
  );
};

export const synchronizeFolders = (items: Item[]) => {
  return (dispatch: any) => {
    const body = JSON.stringify(data(items, true));
    const url = `${getProjectShareUrl()}/User/00000000-0000-0000-0000-000000000000`;
    logger.logInfo(`Started synchronizing folders: ${url}, ${body}`, [
      url,
      body,
    ]);
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          toast(toastType.error, 'Synchronize Folder Failure');
          logger.logError(
            `Failed to synchronize folders: ${response.errorMessage}`,
            [response.errorMessage]
          );
        } else {
          dispatch(fetchSyncedFolders());
          dispatch(invalidateData());
          items.length == 1
            ? toast(toastType.success, 'Synchronize Folder')
            : toast(toastType.success, 'Synchronize Folders');
          logger.logInfo('Synchronized folders successfully');
        }
      });
  };
};

export const unSynchronizeFolders = (items: Item[]) => {
  return (dispatch: any, getState: any) => {
    const body = JSON.stringify(data(items, false));
    const url = `${getProjectShareUrl()}/User/00000000-0000-0000-0000-000000000000`;
    logger.logInfo(`Started un synchronizing folders: ${url}, ${body}`, [
      url,
      body,
    ]);
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          toast(toastType.error, 'Unsynchronize Folder Failure');
          logger.logError(
            `Failed to un synchronize folders: ${response.errorMessage}`,
            [response.errorMessage]
          );
        } else {
          dispatch(fetchSyncedFolders());
          dispatch(invalidateData());
          items.length == 1
            ? toast(toastType.success, 'Unsynchronize Folder')
            : toast(toastType.success, 'Unsynchronize Folders');
          logger.logInfo(' Un synchronized folders successfully');
        }
      });
  };
};

const data = (items: Item[], toSync: boolean): any => {
  let relationShipInstances: any[] = items.map(item => {
    return {
      schemaName: 'ProjectShare',
      className: 'SyncFolder',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'ProjectShare',
        className: 'Folder',
        instanceId: item.instanceId,
        changeState: 'existing',
      },
    };
  });
  if (!toSync) {
    relationShipInstances.map(element => {
      element['changeState'] = 'deleted';
    });
  }
  const data: any = {
    instance: {
      schemaName: 'ProjectShare',
      className: 'User',
      instanceId: '00000000-0000-0000-0000-000000000000',
      changeState: 'existing',
      properties: {},
      relationshipInstances: relationShipInstances,
    },
  };
  return data;
};
