import React, { useEffect } from 'react';
import { FullPageLoader } from '../../components/fullPageLoader/fullPageLoader';
import { useAppConfiguration } from '../../hooks/useAppConfiguration';
import { useParams } from 'react-router-dom';

export const LogOut: React.FunctionComponent<any> = props => {
  const [isConfigurationLoaded, manager] = useAppConfiguration();
  const params = useParams<any>()
  useEffect(() => {
    if (isConfigurationLoaded) {
      manager.signoutRedirect({
        state: { from: `${window.location.origin}/${params.projectId}` },
      });
    }
  }, [isConfigurationLoaded]);
  return <FullPageLoader>Logging out...</FullPageLoader>;
};
