import React, { FC } from 'react';

export type ITabProps = {
  title: string;
  active?: boolean;
  index?: number;
  changeIndex?: () => void;
  badge?: JSX.Element;
  children?: React.ReactNode
};

export const Tab: FC<ITabProps> = (props: ITabProps) => {
  const { title, active = false, index, badge } = props;

  const handleClick = () => {
    if (props.changeIndex) {
      props.changeIndex();
    }
  };

  return (
    <li
      key={'Tab' + index}
      className={active ? 'active' : ''}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="tab"
      style={{ padding: '0px', display: 'inline-flex' }}
    >
      <div
        style={{
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '25px',
          paddingRight: '25px',
          minWidth: '72px',
          textAlign: 'center',
        }}
      >
        {title}
      </div>
      {badge}
    </li>
  );
};
