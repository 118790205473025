// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-pixel {
  background-color: #008be1;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
  display: inline-block;
  animation: blinkpixel 1s infinite alternate; }

.loaderPixel1 {
  animation-delay: 0.2s;
  margin-left: 5px; }

.loaderPixel2 {
  animation-delay: 0.4s; }

.loaderPixel3 {
  animation-delay: 0.6s; }

.loaderPixel4 {
  margin-right: 0px;
  animation-delay: 0.8s; }

.share-loader {
  top: 50%;
  left: 50%; }

.loader-icon {
  background-color: #ffffff;
  width: 70px;
  height: 70px;
  display: block; }

@keyframes blinkpixel {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes blinkpixel {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
`, "",{"version":3,"sources":["webpack://./src/components/icons/ShareLoader.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,2CAA2C,EAAE;;AAE/C;EACE,qBAAqB;EACrB,gBAAgB,EAAE;;AAEpB;EACE,qBAAqB,EAAE;;AAEzB;EACE,qBAAqB,EAAE;;AAEzB;EACE,iBAAiB;EACjB,qBAAqB,EAAE;;AAEzB;EACE,QAAQ;EACR,SAAS,EAAE;;AAEb;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,cAAc,EAAE;;AAElB;EACE;IACE,UAAU,EAAE;EACd;IACE,UAAU,EAAE,EAAE;;AAElB;EACE;IACE,UAAU,EAAE;EACd;IACE,UAAU,EAAE,EAAE","sourcesContent":[".loader-pixel {\n  background-color: #008be1;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  margin-right: 7px;\n  display: inline-block;\n  animation: blinkpixel 1s infinite alternate; }\n\n.loaderPixel1 {\n  animation-delay: 0.2s;\n  margin-left: 5px; }\n\n.loaderPixel2 {\n  animation-delay: 0.4s; }\n\n.loaderPixel3 {\n  animation-delay: 0.6s; }\n\n.loaderPixel4 {\n  margin-right: 0px;\n  animation-delay: 0.8s; }\n\n.share-loader {\n  top: 50%;\n  left: 50%; }\n\n.loader-icon {\n  background-color: #ffffff;\n  width: 70px;\n  height: 70px;\n  display: block; }\n\n@keyframes blinkpixel {\n  0% {\n    opacity: 0; }\n  100% {\n    opacity: 1; } }\n\n@-webkit-keyframes blinkpixel {\n  0% {\n    opacity: 0; }\n  100% {\n    opacity: 1; } }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
