// initialization actions
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_FOLDER_ID = 'SET_FOLDER_ID';
export const SET_URLS = 'SET_URLS';
export const SET_CONTEXT = 'SET_CONTEXT';
export const SET_LINK_FORMAT = 'SET_LINK_FORMAT';
export const SET_IS_INITIALIZED = 'SET_IS_INITIALIZED';
export const SET_FOLDER_ACCESS_ORIGIN = 'SET_FOLDER_ACCESS_ORIGIN';
export const SET_GPRID = 'SET_GPRID';

// rbac actions
export const RECEIVE_RBAC_PERMISSIONS = 'RECEIVE_RBAC_PERMISSIONS';
export const FETCH_RBAC_PERMISSIONS = 'FETCH_RBAC_PERMISSIONS';

// url actions
export const SET_RBAC_URL = 'SET_RBAC_URL';

// data actions
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const SET_IS_DATA_LOADING = 'SET_IS_DATA_LOADING';
export const SET_DATA_ERROR = 'SET_DATA_ERROR';
export const SET_IS_INITIAL_LOAD = 'SET_IS_INITIAL_LOAD';
export const INVALIDATE_DATA = 'INVALIDATE_DATA';
export const INVALIDATE_PAGINATION = 'INVALIDATE_PAGINATION';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGES = 'SET_PAGES';
export const SET_IS_LAST_PAGE = 'SET_IS_LAST_PAGE';
export const SET_IS_SEARCH = 'SET_IS_SEARCH';
export const SET_EMPTY_TABLE_LABEL = 'SET_EMPTY_TABLE_LABEL';

// user actions
export const SET_USER = 'SET_USER';
export const SET_ISADMIN = 'SET_ISADMIN';
// roles
export const SET_SHARE_PERMISSIONS = 'SET_SHARE_PERMISSIONS';
export const SET_USER_ROLES = 'SET_USER_ROLES';

// selection actions
export const SET_SELECTION = 'SET_SELECTION';
export const INVALIDATE_SELECTION = 'INVALIDATE_SELECTION';

// sync folders actions
export const SET_SYNCED_FOLDERS = 'SET_SYNCED_FOLDERS';

// upload actions
export const ENQUEUE_FILES = 'ENQUEUE_FILES';
export const UPLOAD_FINISH = 'UPLOAD_FINISH';
export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
export const FILE_UPLOAD_FINISH = 'FILE_UPLOAD_FINISH';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';

// rbac project permissions actions
export const SET_RBAC_PROJECT_PERMISSIONS = 'SET_RBAC_PROJECT_PERMISSIONS';

// rbac instance permissions actions
export const SET_RBAC_INSTANCE_PERMISSIONS = 'SET_RBAC_INSTANCE_PERMISSIONS';
export const INVALIDATE_RBAC_INSTANCE_PERMISSIONS =
  'INVALIDATE_RBAC_INSTANCE_PERMISSIONS';

// rbac folder type actions
export const SET_RBAC_FOLDER_TYPE = 'SET_RBAC_FOLDER_TYPE';

// breadcrumbs actions
export const BREADCRUMBS_ADD = 'BREADCRUMBS_ADD';
export const BREADCRUMBS_INITIALIZE = 'BREADCRUMBS_INITIALIZE';
export const BREADCRUMBS_JUMP_TO = 'BREADCRUMBS_JUMP_TO';
export const BREADCRUMBS_JUMP_TO_BY_ID = 'BREADCRUMBS_JUMP_TO_BY_ID';
export const SET_ROOT_FOLDER_NAME = 'SET_ROOT_FOLDER_NAME';

// launch darkly feature actions
export const SET_FEATURES = 'SET_FEATURES';

// search actions
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

//audit actions
export const SET_AUDIT_LOADING = 'SET_AUDIT_LOADING';
export const SET_AUDIT_ITEM = 'SET_AUDIT_ITEM';
export const SET_AUDIT_DATA = 'SET_AUDIT_DATA';
export const SET_AUDIT_ERROR = 'SET_AUDIT_ERROR';
