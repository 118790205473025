import {
  BREADCRUMBS_ADD,
  BREADCRUMBS_JUMP_TO,
  BREADCRUMBS_INITIALIZE,
  BREADCRUMBS_JUMP_TO_BY_ID,
  SET_ROOT_FOLDER_NAME,
} from './actionConstants';

export const initializeBreadcrumbs = (values: string[], labels: string[]) => {
  return {
    type: BREADCRUMBS_INITIALIZE,
    payload: { values, labels },
  };
};

export const addBreadcrumb = (value: string, label: string) => {
  return {
    type: BREADCRUMBS_ADD,
    payload: { value, label },
  };
};

export const jumpTo = (value: string) => {
  return {
    type: BREADCRUMBS_JUMP_TO,
    payload: value,
  };
};

export const jumpToById = (index: number, value: string) => {
  return {
    type: BREADCRUMBS_JUMP_TO_BY_ID,
    payload: { index, value },
  };
};

export const setRootFolderName = (name: string) => {
  return {
    type: SET_ROOT_FOLDER_NAME,
    payload: name,
  };
};
