import { IBreadcrumb } from '.';

/**
 * Returns breadcrumb where instanceId is the last breadcrumb
 * If instanceId is not found it will return the first breadcrumb
 * @param breadcrumbs breadcrumb list
 * @param instanceId id to jump to
 */
export function jumpBreadcrumb(breadcrumbs: IBreadcrumb[], instanceId: string) {
  let index = breadcrumbs.findIndex(bc => bc.instanceId == instanceId);
  if (index == -1) {
    index = 0;
  }
  return [...breadcrumbs.slice(0, index + 1)];
}
