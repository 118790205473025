// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary-header {
  height: 44px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #1f9dd7;
  padding-left: 20px;
  font-size: 14px;
  color: #ffffff;
  cursor: default;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
  .secondary-header svg {
    padding-right: 20px; }
  .secondary-header svg > path {
    fill: #FFF; }
`, "",{"version":3,"sources":["webpack://./srcDemoApp/components/secondaryHeader/secondaryHeader.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB,EAAE;EACvB;IACE,mBAAmB,EAAE;EACvB;IACE,UAAU,EAAE","sourcesContent":[".secondary-header {\n  height: 44px;\n  flex-shrink: 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background: #1f9dd7;\n  padding-left: 20px;\n  font-size: 14px;\n  color: #ffffff;\n  cursor: default;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none; }\n  .secondary-header svg {\n    padding-right: 20px; }\n  .secondary-header svg > path {\n    fill: #FFF; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
