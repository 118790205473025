import * as React from 'react';
import { Hopscotch } from './hopscotch';
import { AppState } from '../../store/configureStore';
import { FeaturesState } from '../../reducers/featuresReducer';
import { RbacProjectPermissionsReducer } from '../../reducers/projectPermissionsReducer';
import { connect } from 'react-redux';
import {
  useOnboardingMessages,
  OnboardingMessagesFlags,
} from '../../hooks/useOnboardingMessages';
import { useState } from 'react';

export const Tour = (props: any): any => {
  const [flags, isLoading] = useOnboardingMessages(
    props.productSettingsServiceUrl,
    props.accessToken
  );

  const [isOnboardingTourClosed, setOnboardingTourClosed] = useState<boolean>(
    false
  );

  const shouldShowTour: boolean =
    props.isInitialized && !props.isLoading && !props.error;

  return (
    !isOnboardingTourClosed &&
    !isLoading &&
    shouldShowTour && (
      <Hopscotch
        tour={filterTourSteps(props, flags)}
        flags={flags}
        accessToken={props.accessToken}
        productSettingsServiceUrl={props.productSettingsServiceUrl}
        setOnboardingTourClosed={setOnboardingTourClosed}
      />
    )
  );
};

const filterTourSteps = (props: any, flags: OnboardingMessagesFlags) => {
  //Handle Project Settings
  let tourAfterProjectSettingsFilter = props.tour.steps.filter(
    (step: any) => flags[step.id as keyof typeof flags] == false
  );

  //Handle tour steps for RBAC Write permission
  let tourAfterWriteFilter = tourAfterProjectSettingsFilter;
  if (!props.projectPermissions.rbacWrite) {
    const writeTargets: string[] = [
      'GetStarted', // removes Get Started tour message
      'DragAndDrop', // removes Drag and Drop tour message
      'FileLock', // removes File Lock tour message
      'LocalSync', // removes Local Sync tour message
    ];
    tourAfterWriteFilter = tourAfterWriteFilter.filter(
      (step: any) => -1 === writeTargets.indexOf(step.id)
    );
  }

  //Handle tour steps for RBAC Configure Folder Access permission
  let tourAfterFolderAccessFilter = tourAfterWriteFilter;
  if (
    !props.features.folderAccess ||
    !props.projectPermissions.rbacAllowInstanceSecurity
  ) {
    const folderAccessStepTargets: string[] = [
      'FolderAccess', // removes Folder Access tour message
    ];
    tourAfterFolderAccessFilter = tourAfterFolderAccessFilter.filter(
      (step: any) => -1 === folderAccessStepTargets.indexOf(step.id)
    );
  }

  //Handle tour steps for Audit Trail
  let tourAfterAuditTrailFilter = tourAfterFolderAccessFilter;
  if (!props.features.auditTrail) {
    const auditStepTargets: string[] = [
      'AuditTrail', // removes Audit Trail tour message
    ];
    tourAfterAuditTrailFilter = tourAfterAuditTrailFilter.filter(
      (step: any) => -1 === auditStepTargets.indexOf(step.target)
    );
  }

  return { ...props.tour, steps: tourAfterAuditTrailFilter };
};

type StateProps = {
  isLoading: boolean;
  isInitialized: boolean;
  error: { errorTitle: string; errorDetails: string };
  features: FeaturesState;
  projectPermissions: typeof RbacProjectPermissionsReducer;
};

export const mapStateToProps = (store: AppState): StateProps => {
  return {
    isLoading: store.data.isLoading,
    isInitialized: store.navigation.isInitialized,
    error: store.data.error,
    features: store.features,
    projectPermissions: store.projectPermissions,
  };
};

export default connect(mapStateToProps)(Tour);
