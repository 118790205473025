import { Action } from '../actions/types';
import {
  SET_IS_INITIALIZED,
  SET_PROJECT_ID,
  SET_FOLDER_ID,
  SET_FOLDER_ACCESS_ORIGIN,
  SET_CONTEXT,
  SET_LINK_FORMAT,
  SET_GPRID,
} from '../actions/actionConstants';

export interface NavigationState {
  isInitialized: boolean;
  projectId: string;
  currentFolderId: string;
  currentFolderAccessOrigin: any;
  context: string;
  linkFormat: string;
  GPRId: string;
}

export const initialState: NavigationState = {
  isInitialized: false,
  projectId: '',
  currentFolderId: '',
  currentFolderAccessOrigin: null,
  context: '',
  linkFormat: '',
  GPRId: '',
};

export const NavigationReducer = (
  state: NavigationState = initialState,
  action: Action<any>
) => {
  switch (action.type) {
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload,
      };
    case SET_FOLDER_ID:
      return {
        ...state,
        currentFolderId: action.payload,
      };
    case SET_FOLDER_ACCESS_ORIGIN:
      return {
        ...state,
        currentFolderAccessOrigin: action.payload,
      };
    case SET_CONTEXT:
      return {
        ...state,
        context: action.payload,
      };
    case SET_LINK_FORMAT:
      return {
        ...state,
        linkFormat: action.payload,
      };
    case SET_IS_INITIALIZED:
      return {
        ...state,
        isInitialized: action.payload,
      };
    case SET_GPRID:
      return {
        ...state,
        GPRId: action.payload,
      };
    default:
      return state;
  }
};
