import { useState, useLayoutEffect, useEffect } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect: any = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect!.top,
    left: 'y' in rect ? rect.y : rect!.left,
    x: 'x' in rect ? rect.x : rect!.left,
    y: 'y' in rect ? rect.y : rect!.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

export function useIsWidthSmaller(ref: any, checkWidth: number) {
  const [isSmaller, setIsSmaller] = useState(false);
  const [node, setNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setNode(ref.current);
  }, [ref]);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setIsSmaller(getDimensionObject(node).width < checkWidth)
        );
      measure();

      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
    return undefined;
  }, [node]);

  return isSmaller;
}

export function useIsWidthSmallerBreakpoints(
  ref: any,
  values: any[],
  breakpoints: Array<number>,
  defaultValue: any
) {
  const [value, setValue] = useState();
  const [node, setNode] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    setNode(ref.current);
  }, [ref]);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          const dimensions = getDimensionObject(node);
          let firstMatchingValueIndex = breakpoints.findIndex(
            breakpoint => dimensions.width < breakpoint
          );
          if (firstMatchingValueIndex == -1) {
            setValue(defaultValue);
          } else {
            setValue(values[firstMatchingValueIndex]);
          }
        });
      measure();

      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
    return undefined;
  }, [node]);

  return value;
}
