import {
  SET_SHARE_PERMISSIONS,
  SET_USER_ROLES,
} from '../actions/actionConstants';
import { RolePermission, Role } from '../entities/types';

export interface RoleState {
  rolePermissions: RolePermission[];
  userRoles: Role[];
}

const initialState: RoleState = {
  rolePermissions: [],
  userRoles: [],
};

export const RoleReducer = (state: RoleState = initialState, action: any) => {
  switch (action.type) {
    case SET_SHARE_PERMISSIONS:
      return {
        rolePermissions: action.payload,
        userRoles: state.userRoles,
      };
    case SET_USER_ROLES:
      return {
        rolePermissions: state.rolePermissions,
        userRoles: action.payload,
      };
    default:
      return state;
  }
};
