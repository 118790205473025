// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#OnboardingMessagesHeader {
  text-align: center;
  font-size: large;
  font-weight: bold;
  margin-top: 30px;
  padding-bottom: 16px; }

#OnboardingGrid {
  display: grid;
  grid-template-columns: 400px 25px;
  width: 454px;
  margin: auto; }

#OnboardingGridItem {
  border: 1px solid black;
  margin: 0 -1px -1px 0;
  padding-left: 3px;
  display: inline; }
`, "",{"version":3,"sources":["webpack://./srcDemoApp/components/onboardingMessages/onboardingMessages.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB,EAAE;;AAExB;EACE,aAAa;EACb,iCAAiC;EACjC,YAAY;EACZ,YAAY,EAAE;;AAEhB;EACE,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,eAAe,EAAE","sourcesContent":["#OnboardingMessagesHeader {\n  text-align: center;\n  font-size: large;\n  font-weight: bold;\n  margin-top: 30px;\n  padding-bottom: 16px; }\n\n#OnboardingGrid {\n  display: grid;\n  grid-template-columns: 400px 25px;\n  width: 454px;\n  margin: auto; }\n\n#OnboardingGridItem {\n  border: 1px solid black;\n  margin: 0 -1px -1px 0;\n  padding-left: 3px;\n  display: inline; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
