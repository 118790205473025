// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bnt-truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative; }
`, "",{"version":3,"sources":["webpack://./src/components/truncateText/truncateText.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB,EAAE","sourcesContent":[".bnt-truncate-text {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  position: relative; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
