import {
  SET_RBAC_INSTANCE_PERMISSIONS,
  INVALIDATE_RBAC_INSTANCE_PERMISSIONS,
  SET_RBAC_FOLDER_TYPE,
} from '../actions/actionConstants';

export interface InstancePermissionState {
  folderType: string;
  readPermissions: string[];
  writePermissions: string[];
  deletePermissions: string[];
}

export const initialState: InstancePermissionState = {
  folderType: '',
  readPermissions: [] as string[],
  writePermissions: [] as string[],
  deletePermissions: [] as string[],
};

export const InstancePermissionReducer = (
  state: InstancePermissionState = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_RBAC_FOLDER_TYPE:
      return { ...state, folderType: action.payload };
    case SET_RBAC_INSTANCE_PERMISSIONS:
      return {
        folderType: state.folderType,
        readPermissions: [
          ...state.readPermissions,
          ...action.payload.readPermissions,
        ],
        writePermissions: [
          ...state.writePermissions,
          ...action.payload.writePermissions,
        ],
        deletePermissions: [
          ...state.deletePermissions,
          ...action.payload.deletePermissions,
        ],
      };
    case INVALIDATE_RBAC_INSTANCE_PERMISSIONS:
      return { ...initialState };
    default:
      return state;
  }
};
