import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@bentley/bwc-react/core/ProgressIndicators/Spinner';
import { ProgressBar } from '@bentley/bwc-react/core/ProgressIndicators/ProgressBar';
import SvgDocument from '@bentley/bwc-react/icons/Document';
import { UploadingItem, Status } from '../reducers/uploadReducer';
import './uploadProgressIndicator.css';
import TruncateText from './truncateText/truncateText';
import { Positioner, Position } from '@bentley/bwc-react/utils/Positioner';

export interface UploadProgressIndicatorProps {
  isOpen: boolean;
  onClick: () => void;
  uploadingItems: UploadingItem[];
  parentRef: any;
  isUploading: boolean;
}

export const UploadProgressIndicator: React.FunctionComponent<
  UploadProgressIndicatorProps
> = (props: UploadProgressIndicatorProps): any => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [completedItems, setCompletedItems] = useState<UploadingItem[]>([]);

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      props.parentRef.current &&
      !props.parentRef.current.contains(event.target)
    ) {
      props.onClick!();
    }
  };

  const onUnload = (event: any) => {
    event.preventDefault();
    const confirmationMessage = t('Uploading Is In Progress');
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  useEffect(() => {
    if (props.uploadingItems.length > 0) {
      setCompletedItems(
        props.uploadingItems.filter(
          (uploadingItem: UploadingItem) =>
            uploadingItem.status === Status.Success
        )
      );
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.uploadingItems]);

  useEffect(() => {
    if (props.isUploading) {
      window.addEventListener('beforeunload', onUnload);
      return () => {
        window.removeEventListener('beforeunload', onUnload);
      };
    }
    return;
  }, [props.isUploading]);

  const getUploadPercentage = () =>
    Math.trunc((completedItems.length / props.uploadingItems.length) * 100);

  return (
    props.isOpen &&
    (props.uploadingItems.length > 0 && (
      <Positioner
        getParentRef={() => props.parentRef}
        position={Position.BOTTOM_RIGHT}
      >
        <div className={'progressIndicator'} ref={wrapperRef}>
          <div className={'wrapper'}>
            <div className="progressBody">
              <div className={'progressTitle'}>{t('Progress')} </div>
              <div>
                <ul className="uploadingItemsList">
                  {props.uploadingItems.map(
                    (uploadingItem: UploadingItem, index: number) => (
                      <li
                        className={'uploadingItem'}
                        key={uploadingItem.file.name + index}
                      >
                        <span className={'uploadingItemIcon'}>
                          <SvgDocument />
                        </span>
                        <TruncateText
                          text={uploadingItem.file.name}
                          className={'uploadingItemName'}
                        />
                        <div
                          className={'statusIcon'}
                          data-testid={'statusIcon'}
                        >
                          <div data-testid={'spinner'}>
                            <Spinner
                              value={uploadingItem.progress * 100}
                              style={{ width: 20, height: 20 }}
                              success={uploadingItem.status === Status.Success}
                              error={uploadingItem.status === Status.Error}
                            />
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className={'progressFooter'}>
              <div className={'progressIndicatorBar'}>
                <ProgressBar
                  labelLeft={t('ProgressIndicatorLeftLabel', {
                    completedItems: completedItems.length,
                    totalItems: props.uploadingItems.length,
                  })}
                  labelRight={t('ProgressIndicatorRightLabel', {
                    uploadPercentage: getUploadPercentage(),
                  })}
                  value={getUploadPercentage()}
                ></ProgressBar>
              </div>
            </div>
          </div>
        </div>
      </Positioner>
    ))
  );
};

export const mapStateToProps = (store: any) => {
  return {
    uploadingItems: store.upload.uploadingItems,
    isUploading: store.upload.isLoading,
  };
};

export default connect(mapStateToProps)(UploadProgressIndicator);
