import * as React from 'react';
export const SvgXlsx = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path opacity={0.4} fill="#231F20" d="M3 0v16h13V4.6L11.4 0z" />
    <path fill="#FFF" d="M4 15V1h7l4 4v10z" />
    <g fillRule="evenodd" clipRule="evenodd" fill="#86BFA0">
      <path d="M9 5h2v1H9zM12 5h2v1h-2zM9 9h2v1H9zM12 9h2v1h-2zM9 7h2v1H9zM12 7h2v1h-2zM9 11h2v1H9zM12 11h2v1h-2zM9 13h2v1H9zM12 13h2v1h-2z" />
    </g>
    <g fillRule="evenodd" clipRule="evenodd" fill="#999">
      <path d="M6 3h2v1H6zM9 3h2v1H9zM12 3h2v1h-2zM6 5h2v1H6zM6 9h2v1H6zM6 7h2v1H6zM6 11h2v1H6zM6 13h2v1H6z" />
    </g>
    <path fill="#FFF" d="M11 5V1l4 4z" />
    <path opacity={0.4} fill="#231F20" d="M11 5V1h-1v5h5V5z" />
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M4 2.5v11.9l4 .6V2z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#207347"
        d="M0 4l7-1v11l-7-1.1V4z"
      />
      <path
        fill="#FFF"
        d="M5.7 11H4.5l-.9-1.7-.9 1.7H1.5L3 8.7 1.6 6.4h1.2L3.6 8l.9-1.6h1.1L4.3 8.7 5.7 11z"
      />
    </g>
  </svg>
);
export default SvgXlsx;
