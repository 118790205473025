import { WebStorageStateStore, UserManager } from 'oidc-client';

const stripTrailingSlash = (str: any) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const CreateManager = (authority?: string, client_id?: string) => {
  return new UserManager({
    authority: stripTrailingSlash(authority),
    client_id,
    redirect_uri: `${window.location.origin}/signin-oidc`,
    silent_redirect_uri: `${window.location.origin}/silent-signin-oidc`,
    automaticSilentRenew: true,
    response_type: 'code',
    scope:
      'openid email profile organization global_roles feature_tracking context-registry-service product-settings-service projectwise-share-2567 rbac-service ulas-realtime-log-posting-2733 log-dispatcher-2760:write',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
    accessTokenExpiringNotificationTime: 2000,
  });
};
