import { v4 as uuid } from 'uuid';
import { User } from 'oidc-client';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { store } from '../store/configureStore';

const featureMap = new Map<string, string>([
  ['Portal_Search', 'DB4B715A-8197-4FC8-8964-825908406F3A'],
  ['Portal_Upload', '75E19EAF-A40A-4FEB-95CE-EBE34BF3F9BF'],
  ['Portal_Update', '50952C38-0C44-4798-A299-7E139322C21F'],
  ['Portal_CreatePublishingJob', '5F714486-D28E-4A45-AD9C-4051A22B2880'],
  ['Portal_GetLink', 'D6183680-1DB1-4B1A-AD06-21BCE368810C'],
  ['Portal_RecycleAction', '47DA90D2-3B62-4B87-AAFE-0E54C4F23887'],
  ['Portal_Restore', 'F3242F4F-DA88-4B3F-970F-91A485622015'],
  ['Portal_Rename', '912E1F17-2CA6-4227-AA45-AD8A3DE4C64C'],
  ['Portal_Move', '9E1A6C4E-47BE-44D3-9A97-D034BD95EB55'],
  ['Portal_Download', '21BF2D70-DF5E-4EA1-B5D7-341A1D2EA081'],
  ['Portal_Delete', 'AA532F39-EC4B-44DF-90C5-311093D217A4'],
  ['Portal_CreateFolder', 'AB671381-C178-48DE-8E1E-728C1BD33024'],
  ['Portal_PdflinkClick', 'D75D7666-788A-4A84-AFBF-B9D7ECFFDCF5'],
  ['Portal_LockFile', '8DB3203B-EFE5-4E27-B815-C4B00E7C07E1'],
  ['Portal_UnLockFile', '30FD60D1-0DE8-4CBE-8EE7-2E80C05D441C'],
  ['Portal_LockFilesInFolder', '7337A84D-0F86-431A-B7AA-0F40C014640C'],
  ['Portal_UnLockFilesInFolder', 'F1726051-386F-4009-94C3-FDC5BA43846E'],
  ['Portal_AuditTrail', '71731DA8-66E9-4780-9DA9-0095557C15EB'],
  ['Portal_SetFolderAccess', '10F7CAB5-01AA-4BFC-A901-F016035BF1F5'],
  ['Portal_FolderSetSynchronization', '70112086-9df7-44db-bd71-8be0681323fa'],
  [
    'Portal_FolderUnSetSynchronization',
    'e4b56baf-7b81-4af3-a8cc-654a6c68bdf9 ',
  ],
  ['Portal_CreateVersion', '5b5cafd1-9184-429f-98ec-32494743f476 '],
  ['Portal_WopiUsage', '72422885-6287-4858-b8ec-68e13d9aeda7'],
]);

interface Options {
  userId: string;
  userEmail: string;
  organization: string;
  productId: number;
  ultimateSite: number;
  url: string;
  projectId: string;
  applicationInsightsKey: string | undefined;
}
interface FeatureTracker {
  initiliseTracker: (
    user: User,
    featureTrackServiceUrl: string,
    applicationInsightsKey: string | undefined,
    projectId: string
  ) => void;
  trackfeature: (featureId: string) => void;
}
export const featureTracker = ((): FeatureTracker => {
  let options: Options;
  let appInsights: any;
  return {
    initiliseTracker: (
      user: User,
      featureTrackServiceUrl: string,
      applicationInsightsKey: string | undefined,
      projectId: string
    ) => {
      // ulas options
      options = {
        userId: user.profile.sub,
        userEmail: user.profile.email!,
        organization: user.profile.org_name,
        productId: 2567,
        ultimateSite: Number(user.profile.ultimate_site),
        url: featureTrackServiceUrl,
        projectId: projectId,
        applicationInsightsKey: applicationInsightsKey,
      };
      // app insights
      if (applicationInsightsKey != null) {
        appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: applicationInsightsKey,
            //disableFetchTracking: false,
            //disableTelemetry: location.origin.includes('localhost'),
          },
        });
        appInsights.loadAppInsights();
      }
    },
    trackfeature: (featureName: string) => {
      const date = new Date().toISOString();
      const body = JSON.stringify([
        {
          ultID: options.ultimateSite,
          pid: options.userId,
          imsID: options.userId,
          hID: window.location.host,
          uID: options.userEmail,
          orgName: options.organization,
          polID: uuid(),
          secID: uuid(),
          prdid: options.productId,
          fstr: '',
          ver: 1000000000000,
          projID: options.projectId,
          corID: uuid(),
          evTimeZ: date,
          lVer: 1.0,
          lSrc: 'RealTime',
          country: '',
          uType: 'Production',
          ftrID: featureMap.get(featureName),
          sDateZ: date,
          eDateZ: date,
          uData: [],
        },
      ]);

      fetch(`${options.url}/featureLog`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + store.getState().user.accessToken,
          'accept-language': 'en-US',
          accept: 'application/json',
          'content-type': 'application/json; charset=utf-8',
        },
        body,
      });
      if (options.applicationInsightsKey != null) {
        appInsights.trackEvent({
          name: featureName,
          properties: {
            featureId: featureMap.get(featureName),
            org: options.organization,
            userId: options.userId,
            userEmail: options.userEmail,
            projectId: options.projectId,
            productId: options.productId,
          },
        });
      }
    },
  };
})();
