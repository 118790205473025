// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px; }
`, "",{"version":3,"sources":["webpack://./srcDemoApp/app.css"],"names":[],"mappings":"AAAA;EACE,wEAAwE;EACxE,eAAe,EAAE","sourcesContent":["body {\n  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;\n  font-size: 14px; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
