// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu-icon {
  width: 20px;
  height: 16px;
  fill: #677480;
  cursor: pointer; }

.context-menu-icon :hover {
  fill: #008be1; }

.positioner {
  z-index: 101; }
`, "",{"version":3,"sources":["webpack://./src/components/contextMenu/contextMenu.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe,EAAE;;AAEnB;EACE,aAAa,EAAE;;AAEjB;EACE,YAAY,EAAE","sourcesContent":[".context-menu-icon {\n  width: 20px;\n  height: 16px;\n  fill: #677480;\n  cursor: pointer; }\n\n.context-menu-icon :hover {\n  fill: #008be1; }\n\n.positioner {\n  z-index: 101; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
