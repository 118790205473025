import './auditInfo.css';

import React, { useEffect, useState } from 'react';
import { FormAuditTrail } from '../auditTrail/formAuditTrail';
import { Spinner } from '@bentley/bwc-react/core/ProgressIndicators';
import { bindActionCreators } from 'redux';
import { fetchAuditData } from '../../actions/auditActions';
import { IAuditRecord } from '../auditTrail/auditRecord';
import { useTranslation } from 'react-i18next';
import TransparentButton from '@bentley/bwc-react/innersource/InfoPanel/TransparentButton/TransparentButton';
import { connect } from 'react-redux';

const requestRecords = 24;
const showRecords = 12;

export interface AuditInfoProps {
  isOpen: boolean;

  currentItem: any;
  loadedRecords?: IAuditRecord[];
  haveMoreData: boolean;
  isLoading: boolean;
  error?: string;

  fetchAuditData: (top: number) => any;
}

export const AuditInfo: React.FunctionComponent<AuditInfoProps> = (
  props: any
): any => {
  const [moreRecords, setMoreRecords] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    //start to get audit data
    if (
      props.isOpen &&
      props.currentItem &&
      !props.isLoading &&
      !props.loadedRecords &&
      !props.error
    ) {
      props.fetchAuditData(requestRecords);
    }
  }, [
    props.isOpen,
    props.currentItem,
    props.isLoading,
    props.loadedRecords,
    props.error,
  ]);

  const showMoreData = () => {
    if (props.haveMoreData) {
      props.fetchAuditData();
    }
    setMoreRecords(false);
  };

  const showLessData = () => {
    setMoreRecords(true);
  };

  const getShowRecords = (records: any) => {
    return moreRecords && records && records.length > showRecords
      ? records.slice(0, showRecords)
      : records;
  };

  return (
    <div data-testid="auditInfo-container" className="auditInfo-container">
      {!props.isLoading &&
        !props.error &&
        (props.loadedRecords && props.loadedRecords.length > 0 ? (
          <FormAuditTrail auditRecords={getShowRecords(props.loadedRecords)} />
        ) : (
          <div data-testid="auditInfo-message" className="auditInfo-message">
            {t('No records found')}
          </div>
        ))}

      {moreRecords &&
        !props.isLoading &&
        props.loadedRecords &&
        props.loadedRecords.length > showRecords && (
          <TransparentButton
            className="more-button"
            onClick={showMoreData}
            data-testid={'transparent-button more-button'}
          >
            {t('Show more') as string}
          </TransparentButton>
        )}

      {!moreRecords && !props.isLoading && (
        <TransparentButton
          className="less-button"
          onClick={showLessData}
          data-testid={'transparent-button less-button'}
        >
          {t('Show less') as string}
        </TransparentButton>
      )}

      {props.isLoading && (
        <div
          data-testid="bnt-audit-loading-spinner"
          className="bnt-audit-loading-spinner"
        >
          <Spinner
            style={{ width: 48, height: 48, alignSelf: 'center' }}
            indeterminate={true}
          />
        </div>
      )}

      {props.error && (
        <div data-testid="auditInfo-message" className="auditInfo-message">
          {t('Failed to retrieve audit data')}
        </div>
      )}
    </div>
  );
};

// export default AuditInfo;

export const mapStateToProps = (store: any) => {
  return {
    currentItem: store.audit.currentItem,
    loadedRecords: store.audit.loadedRecords,
    haveMoreData: store.audit.haveMoreData,
    isLoading: store.audit.isLoading,
    error: store.audit.error,
  };
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchAuditData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AuditInfo);
