// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreen-error-page {
  width: 450px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  text-align: center; }
`, "",{"version":3,"sources":["webpack://./src/components/errorPages/genericErrorPage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB,EAAE","sourcesContent":[".fullscreen-error-page {\n  width: 450px;\n  max-width: 450px;\n  display: flex;\n  flex-direction: column;\n  text-align: center; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
