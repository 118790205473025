import { toaster } from '@bentley/bwc-react/core';
import i18n from '../i18n';

export enum toastType {
  success,
  information,
  error,
}

export const toast = (
  messageType: toastType,
  message: string,
  options: any = {},
  closeAll: boolean = true
) => {
  if (closeAll) {
    toaster.closeAll();
  }

  if (messageType == toastType.success) {
    toaster.success(i18n.t(message), {
      type: 'temporary',
      ...options,
    });
  } else if (messageType == toastType.information) {
    toaster.informational(i18n.t(message), {
      type: 'temporary',
      ...options,
    });
  } else {
    toaster.error(i18n.t(message), {
      type: 'temporary',
      ...options,
    });
  }
};
