import React, { useEffect } from 'react';
import { LabeledInput } from '@bentley/bwc-react/core';
import { useInputField } from '../../hooks/useInputField';
import {
  MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME,
  itemNameRegex,
} from '../../constants';
import { ILabeledInputProps } from '@bentley/bwc-react/core/LabeledInput/LabeledInput';
import { useTranslation } from 'react-i18next';

export type FolderNameInputProps = {
  setFolderName: (name: string) => void;
  setIsInputInvalid: (value: boolean) => void;
  forwardedRef: React.RefObject<HTMLInputElement>;
} & Omit<ILabeledInputProps, 'ref'>;

export const FolderNameInput: React.FunctionComponent<
  FolderNameInputProps
> = props => {
  const { setFolderName, setIsInputInvalid, forwardedRef, ...rest } = props;

  const [
    status,
    errorMessage,
    isInputInvalid,
    setInputFieldState,
  ] = useInputField();

  const { t } = useTranslation();

  useEffect(() => {
    setIsInputInvalid(isInputInvalid);
  }, [isInputInvalid]);

  const validate = (folderName: string) => {
    if (!folderName || !folderName.trim()) {
      setInputFieldState('error', t('Folder name cannot be empty'));
    } else if (folderName.match(itemNameRegex)) {
      setInputFieldState(
        'error',
        t('Folder Create Failure Invalid Characters', {
          invalidCharacters: '/ : * ? " <> |',
        })
      );
    } else if (folderName.length > MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME) {
      setInputFieldState(
        'error',
        t('Item name is too long', {
          maxCharactersAllowed: MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME,
        })
      );
    } else {
      setInputFieldState('success', '');
    }
  };

  return (
    <LabeledInput
      onChange={(e: any) => {
        validate(e.target.value);
        setFolderName(e.target.value);
      }}
      status={status}
      message={errorMessage}
      ref={forwardedRef}
      {...rest}
    />
  );
};
