import { useState, useEffect } from 'react';
import { Urls } from '../../index';
import { UserManager } from 'oidc-client';
import { CreateManager } from '../services/UserManager';

export interface Configuration {
  authority: string;
  clientId: string;
  buddiUrls: Urls;
  buddiRegion: string;
  applicationInsightsinstrumentationKey: string;
}

async function getConfigurationFromBackend() {
  return (await (
    await fetch('/api/Configurations/Configuration')
  ).json()) as Configuration;
}

export const useAppConfiguration = (): [boolean, any, Configuration] => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [configuration, setConfiguration] = useState<Configuration>(
    {} as Configuration
  );
  const [manager, setManager] = useState<UserManager>();

  useEffect(() => {
    loadAppConfiguration();

    async function loadAppConfiguration() {
      const config = await getConfigurationFromBackend();
      setManager(CreateManager(config.authority, config.clientId));
      setConfiguration(config);
      setIsLoaded(true);
    }
  }, []);
  return [isLoaded, manager, configuration];
};
