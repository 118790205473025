import { invalidateData } from './dataActions';
import { getProjectShareUrl, getFileType } from '../services/utillities';
import { defaultShareOptions } from '../services/fetchHelper';
import { Item } from '../entities/entities';
import { toast, toastType } from '../services/toastService';
import { logger } from '../services/logger';

export const renameItem = (item: Item, newName: string) => {
  return (dispatch: any, getState: any) => {
    const url = `${getProjectShareUrl()}/${item.className}/${item.instanceId}`;
    const body = JSON.stringify(data(item, newName));
    logger.logInfo(`Started renaming item: ${url}, ${body}`, [url, body]);
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          logger.logError(`Failed to rename item: ${response.errorMessage}`, [
            response.errorMessage,
          ]);
          toast(toastType.error, 'Rename Item Failure');
        } else {
          dispatch(invalidateData());
          if (document.getElementsByClassName('rt-tbody')[0]) {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }
          logger.logInfo('Renamed item successfully');
          toast(toastType.success, 'Rename Item');
        }
      });
  };
};

const data = (item: Item, newName: string) => {
  return {
    instance: {
      instanceId: item.instanceId,
      schemaName: 'ProjectShare',
      className: item.className,
      properties: {
        Name: newName,
        ContentType: item.className == 'File' ? getFileType(newName) : 'Folder',
      },
      relationshipInstances: [],
      changeState: 'modified',
    },
  };
};
