import * as React from 'react';
import './auditDayRecords.css';

import { AuditRecord, IAuditRecord } from './auditRecord';
import { getLocale } from '../../services/dates';

export type AuditDayRecordsProps = {
  className?: string;
  auditDayRecords: IAuditRecord[];
  date: Date;
};

export const AuditDayRecords = (props: AuditDayRecordsProps) => {
  const { className, auditDayRecords, date } = props;

  const getDate = (): string => {
    const month = date.toLocaleString(getLocale(), {
      month: 'short',
    });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <div
      data-testid={'bnt-audit-day-records' + (className ? ' ' + className : '')}
      className={'bnt-audit-day-records' + (className ? ' ' + className : '')}
    >
      <div
        data-testid="bnt-audit-day-records-date"
        className={'bnt-audit-day-records-date'}
      >
        {getDate()}
      </div>
      {auditDayRecords.map((item, index) => (
        <AuditRecord auditRecord={item} key={index} />
      ))}
    </div>
  );
};

export default AuditDayRecords;
