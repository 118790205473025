import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { LabeledInput, Alert } from '@bentley/bwc-react/core';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { renameItem } from '../../actions/renameItemActions';
import { Item } from '../../entities/entities';
import { bindActionCreators } from 'redux';
import { invalidateSelection } from '../../actions/selectionActions';
import { useInputField } from '../../hooks/useInputField';
import {
  getFileExtension,
  getNameWithoutExtension,
} from '../../services/utillities';
import {
  itemNameRegex,
  MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME,
} from '../../constants';
import { useTranslation } from 'react-i18next';
import './modalInputs.css';

export interface RenameItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  renameItem: (item: Item, newName: string) => void;
  invalidateSelection: () => void;
  selection: Item[];
}

export const RenameItemModal: React.FunctionComponent<RenameItemModalProps> = (
  props
): any => {
  const [itemName, setItemName] = useState('');
  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const [showWarningForXfdf, setShowWarningForXfdf] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [
    status,
    errorMessage,
    isInputInvalid,
    setInputFieldState,
  ] = useInputField();

  const close = () => {
    setItemName('');
    setInputFieldState('', '');
    props.onClose();
    setShouldShowWarning(false);
    setShowWarningForXfdf(false);
  };

  useEffect(() => {
    if (props.selection.length !== 0) {
      setItemName(props.selection[0].name);
    }

    if (inputRef != null && inputRef.current != null) {
      const nameWithoutExtension = getNameWithoutExtension(
        props.selection[0].name
      );
      //@ts-ignore
      const input = inputRef.current as HTMLInputElement;
      input.focus();
      setTimeout(function() {
        input.setSelectionRange(0, nameWithoutExtension.length);
      }, 0);
    }
  }, [props.isOpen]);

  const performRename = () => {
    if (getFileExtension(props.selection[0].name) === 'xfdf') {
      setShowWarningForXfdf(true);
    } else {
      getFileExtension(props.selection[0].name) !==
      getFileExtension(itemName.trim())
        ? setShouldShowWarning(true)
        : rename();
    }
  };

  const rename = () => {
    props.renameItem(props.selection[0], itemName.trim());
    props.invalidateSelection();
    close();
  };

  const validate = (itemName: string) => {
    if (!itemName || !itemName.trim()) {
      setInputFieldState('error', t('Rename Failure Empty Item Name'));
    } else if (itemName.length > MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME) {
      setInputFieldState(
        'error',
        t('Item name is too long', {
          maxCharactersAllowed: MAX_CHARACTERS_ALLOWED_IN_ITEM_NAME,
        })
      );
    } else if (itemName.match(itemNameRegex)) {
      setInputFieldState(
        'error',
        t('Rename Failure Invalid Characters', {
          invalidCharacters: '/ : * ? " <> |',
        })
      );
    } else if (itemName.trim() == props.selection[0]!.name.trim()) {
      setInputFieldState('error', t('Rename Failure Same Item Name'));
    } else {
      setInputFieldState('success', '');
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.charCode == 13) {
      // Enter
      if (!isInputInvalid) {
        performRename();
      }
    }
  };

  return (
    <Modal
      modalRootId="modal-root"
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Rename item modal title')}
      closeHandle={close}
      isPrimaryDisabled={isInputInvalid}
      primaryButtonHandle={
        !shouldShowWarning && !showWarningForXfdf ? performRename : rename
      }
      primaryButtonLabel={t('Rename')}
      secondaryButtonHandle={close}
      secondaryButtonLabel={t('Cancel')}
    >
      {!shouldShowWarning && !showWarningForXfdf ? (
        <LabeledInput
          label={t('Item name')}
          value={itemName}
          data-testid="get-rename-input"
          onKeyPress={onKeyPress}
          onChange={(e: any) => {
            validate(e.target.value);
            setItemName(e.target.value);
          }}
          status={status}
          message={errorMessage}
          ref={inputRef}
        />
      ) : showWarningForXfdf ? (
        <Alert type="warning" data-testid="get-alert-for-xfdf">
          {t('Rename Xfdf File Confirmation') as string}
        </Alert>
      ) : (
        <Alert type="warning" data-testid="get-alert">
          {t('Change file extension', {
            oldExtension: getFileExtension(props.selection[0].name),
            newExtension: getFileExtension(itemName),
          }) as string}
        </Alert>
      )}
    </Modal>
  );
};

export const mapStateToProps = (store: any) => {
  return {
    selection: store.selection.selectedItems,
  };
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ renameItem, invalidateSelection }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RenameItemModal);
