import { store } from '../store/configureStore';
import { Item } from '../entities/entities';
import {
  isSyncedFolder,
  getUnSyncFolders,
} from '../actions/synchronizeFoldersActions';
import { User } from 'oidc-client';

// Is access accessOrigin cached
export const hasItemReadPermissions = (accessOrigin: string): boolean =>
  accessOrigin == null
    ? store.getState().projectPermissions.rbacRead
    : store
        .getState()
        .instancePermissions.readPermissions.indexOf(accessOrigin) !== -1;

export const hasItemWritePermissions = (accessOrigin: string): boolean =>
  accessOrigin == null
    ? store.getState().projectPermissions.rbacWrite
    : store
        .getState()
        .instancePermissions.writePermissions.indexOf(accessOrigin) !== -1;

export const hasItemDeletePermissions = (accessOrigin: string): boolean =>
  accessOrigin == null
    ? store.getState().projectPermissions.rbacDelete
    : store
        .getState()
        .instancePermissions.deletePermissions.indexOf(accessOrigin) !== -1;

export const hasAdminPermissions = (
  user: User,
  contextUltimateRefId: string
) => {
  return (
    user.profile.role &&
    user.profile.role.indexOf('Account Admin') !== -1 &&
    user.profile.org === contextUltimateRefId
  );
};

// Do all items have instance permissions
export const haveAllItemsReadPermission = (checkedRows: Item[]): boolean =>
  checkedRows.every(row => hasItemReadPermissions(row.accessOrigin));

export const haveAllItemsWritePermission = (checkedRows: Item[]): boolean =>
  checkedRows.every(row => hasItemWritePermissions(row.accessOrigin));

export const haveAllItemsDeletePermission = (checkedRows: Item[]): boolean =>
  checkedRows.every(row => hasItemDeletePermissions(row.accessOrigin));

//Contains methods
export const containsVersionedFile = (checkedRows: Item[]): boolean =>
  checkedRows.some(
    row =>
      row.version != null && row.version.length > 0 && row.version !== 'Current'
  );

export const containsLockedByOther = (checkedRows: Item[]): boolean =>
  checkedRows.some(
    row => row.locked && row.modifiedBy !== store.getState().user.id
  );

export const isFolder = (item: Item): boolean => item.className === 'Folder';

export const containsFolder = (checkedRows: Item[]): boolean =>
  checkedRows.some(isFolder);

export const isFile = (item: Item): boolean => item.className === 'File';

export const containsFile = (checkedRows: Item[]): boolean =>
  checkedRows.some(isFile);

export const containsLockedFile = (checkedRows: Item[]): boolean =>
  checkedRows.some(row => row.locked);

// Are all Items
export const areAllItemsIdgn = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  checkedRows.every(
    row => row.className === 'File' && row.name.endsWith('i.dgn')
  );

// Allow methods
export const allowDownload = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 && haveAllItemsReadPermission(checkedRows);

export const allowMove = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  !containsVersionedFile(checkedRows) &&
  !containsLockedByOther(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowRename = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 &&
  !containsVersionedFile(checkedRows) &&
  !containsLockedByOther(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowRecycle = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  !containsVersionedFile(checkedRows) &&
  !containsLockedByOther(checkedRows) &&
  haveAllItemsDeletePermission(checkedRows);

export const allowFilesLockUnlockInFolder = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  containsFolder(checkedRows) &&
  !containsFile(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowLock = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  !containsFolder(checkedRows) &&
  !containsLockedFile(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowUnlock = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  !containsFolder(checkedRows) &&
  containsLockedFile(checkedRows) &&
  (store.getState().user.isAdmin
    ? true
    : !containsLockedByOther(checkedRows)) &&
  haveAllItemsWritePermission(checkedRows);

export const allowPublish = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  areAllItemsIdgn(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowGetLink = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 && haveAllItemsReadPermission(checkedRows);

export const allowGetDownloadLink = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 &&
  checkedRows[0].className === 'File' &&
  haveAllItemsReadPermission(checkedRows);

export const allowPromoteVersion = (
  versionedFileInstanceId: string,
  checkedRows: Item[]
): boolean =>
  checkedRows.length === 1 &&
  checkedRows[0].instanceId !== versionedFileInstanceId &&
  !containsLockedByOther(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowCreateVersion = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 &&
  !containsLockedByOther(checkedRows) &&
  !containsFolder(checkedRows) &&
  haveAllItemsWritePermission(checkedRows);

export const allowDownloadVersion = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 && haveAllItemsReadPermission(checkedRows);

export const allowPostToImodelHub = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 && areAllItemsIdgn(checkedRows);

export const allowInstanceSecurity = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 &&
  containsFolder(checkedRows) &&
  store.getState().projectPermissions.rbacAllowInstanceSecurity &&
  (checkedRows[0].accessOrigin == null ||
    checkedRows[0].accessOrigin === checkedRows[0].instanceId);

export const areAllFoldersSynced = (checkedRows: Item[]) => {
  const unSyncedFolders = getUnSyncFolders(checkedRows);
  return unSyncedFolders.length === 0;
};

export const areAllFoldersUnSynced = (checkedRows: Item[]) => {
  const unSyncedFolders = getUnSyncFolders(checkedRows);
  return unSyncedFolders.length === checkedRows.length;
};

export const areAllParentsUnSynced = () => {
  const parentFolders = store.getState().breadcrumbs.values;
  return !parentFolders.find((item: string) => isSyncedFolder(item));
};

export const allowSync = (checkedRows: Item[]): boolean =>
  checkedRows.length > 0 &&
  haveAllItemsWritePermission(checkedRows) &&
  ((areAllFoldersSynced(checkedRows) && areAllParentsUnSynced()) ||
    (areAllFoldersUnSynced(checkedRows) && areAllParentsUnSynced()));

export const allowSetFolderAccess = (checkedRows: Item[]): boolean =>
  checkedRows.length === 1 &&
  checkedRows[0].className == 'Folder' &&
  store.getState().projectPermissions.rbacAllowInstanceSecurity &&
  (checkedRows[0].accessOrigin == null ||
    checkedRows[0].accessOrigin === checkedRows[0].instanceId);
