import React from 'react';
import './noDataGridStyle.css';
import { Button } from '@bentley/bwc-react/core';
import i18n from '../../i18n';
import { Subheading } from '@bentley/bwc-react/core/Typography/Subheading';
import { Body } from '@bentley/bwc-react/core/Typography/Body';
export interface NoDataGridProps {
  isLoading: boolean;
  image: any;
  noFilesText: string;
  noFilesExplanation?: string;
  showBrowse: boolean;
  onClick?: any;
  disabled?: boolean;
}

export const NoDataGrid: React.FunctionComponent<NoDataGridProps> = props => {
  return !props.isLoading ? (
    <div className="NoDataGrid">
      {props.image}
      <Subheading
        variant={true}
        placeholder={undefined}
      >
        {props.noFilesText}
      </Subheading>
      {props.noFilesExplanation && (
        <Body
          placeholder={undefined}
        >
          {props.noFilesExplanation}
        </Body>
      )}
      {props.showBrowse && (
        <Button
          styleType="hollow"
          title={'Browse'}
          className="empty-state-btn bwc-buttons-blue"
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {i18n.t('Browse') as string}
        </Button>
      )}
    </div>
  ) : (
    <></>
  );
};

export default NoDataGrid;
