import { Action } from '../actions/types';
import {
  BREADCRUMBS_ADD,
  BREADCRUMBS_INITIALIZE,
  BREADCRUMBS_JUMP_TO,
  BREADCRUMBS_JUMP_TO_BY_ID,
  SET_ROOT_FOLDER_NAME,
} from '../actions/actionConstants';

export interface BreadcrumbsState {
  values: string[];
  labels: string[];
  depth: number;
  rootFolderName: string;
}

export const initialState: BreadcrumbsState = {
  values: [],
  labels: [],
  depth: 0,
  rootFolderName: '',
};

export const BreadcrumbsReducer = (
  state: BreadcrumbsState = initialState,
  action: Action<any>
) => {
  switch (action.type) {
    case BREADCRUMBS_ADD:
      return {
        ...state,
        values: [...state.values, action.payload.value],
        labels: [...state.labels, action.payload.label],
        depth: state.depth + 1,
      };
    case BREADCRUMBS_INITIALIZE:
      return {
        ...state,
        values: action.payload.values,
        labels: action.payload.labels,
        depth: action.payload.labels.length - 1,
      };
    case BREADCRUMBS_JUMP_TO: {
      const index = state.values.indexOf(action.payload);
      const depth = index == -1 ? 0 : index;
      return {
        ...state,
        values: state.values.slice(0, depth + 1),
        labels: state.labels.slice(0, depth + 1),
        depth: depth,
      };
    }
    case BREADCRUMBS_JUMP_TO_BY_ID: {
      const index = action.payload.index;
      const depth = index == -1 ? 0 : index;
      const values = state.values.slice(0, depth + 1);
      values[index] = action.payload.value;
      return {
        ...state,
        values: values,
        labels: state.labels.slice(0, depth + 1),
        depth: depth,
      };
    }
    case SET_ROOT_FOLDER_NAME: {
      return {
        ...state,
        rootFolderName: action.payload,
      };
    }

    default:
      return state;
  }
};
