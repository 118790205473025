import * as React from 'react';
export const SvgCheckedout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <defs>
      <style>{'.Checkedout_svg__cls-2{fill:#fff}'}</style>
    </defs>
    <g id="Checkedout_svg__Layer_2" data-name="Layer 2">
      <g id="Checkedout_svg__destroyed">
        <path
          d="M8 1a7 7 0 1 0 7 7 7 7 0 0 0-7-7zM6.93 11.26l-3.2-3.2L4.9 6.88l2 2L11 4.74l1.23 1.17z"
          fill="#cb2026"
        />
        <path
          className="Checkedout_svg__cls-2"
          d="M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm0 15a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"
        />
        <path
          className="Checkedout_svg__cls-2"
          d="M6.93 8.91L4.9 6.88 3.73 8.05l3.2 3.21 5.34-5.34-1.23-1.18-4.11 4.17z"
        />
      </g>
    </g>
  </svg>
);
export default SvgCheckedout;
