import React from 'react';
import './secondaryHeader.css';
import SvgShareIcon from '../../../src/components/icons/ShareIcon';

export const SecondaryHeader = () => {
  return (
    <div className="secondary-header">
      <div>
        <SvgShareIcon style={{ width: '24px' }} />
      </div>
      <div>Share</div>
    </div>
  );
};
