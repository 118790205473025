import cx from 'classnames';
import * as React from 'react';
import './ShareLoader.css';
import SvgShareIcon from './ShareIcon';

type ShareLoaderProps = {
  className: string;
  loading: boolean;
  loadingText: string;
};

export const ShareLoader: React.FC<ShareLoaderProps> = props => {
  return (
    <div className={cx('loading', { active: props.loading }, props.className)}>
      <div className={'share-loader'}>
        <div className="loader-icon">
          <SvgShareIcon />
        </div>
        <div className="loader-pixel loaderPixel1"></div>
        <div className="loader-pixel loaderPixel2"></div>
        <div className="loader-pixel loaderPixel3"></div>
        <div className="loader-pixel loaderPixel4"></div>
      </div>
    </div>
  );
};
