import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Item } from '../../entities/entities';
import Modal from '@bentley/bwc-react/core/Modal/Modal';
import { LabeledInput, LabeledTextarea } from '@bentley/bwc-react/core';
import { Spinner } from '@bentley/bwc-react/core/ProgressIndicators/Spinner';
import { fetchVersions, createVersion } from '../../actions/versionActions';
import {
  useInputField,
  useOptionalInputField,
} from '../../hooks/useInputField';

export interface CreateVersionModalProps {
  isOpen: boolean;
  selection: Item[];
  onClose: () => void;
  fetchVersions: (item: Item) => any;
  createVersion: (item: Item, version: string, comment: string) => void;
}

export const CreateVersionModal: React.FunctionComponent<
  CreateVersionModalProps
> = (props: any): any => {
  const [versionNumber, setVersionNumber] = useState('');
  const [comment, setComment] = useState('');
  const [latestVersion, setLatestVersion] = useState('');
  const [versions, setVersions] = useState<string[]>([]);
  const versionNumberInputRef = useRef(null);
  const [isFetchingVersion, setIsFetchingVersion] = useState(true);
  const { t } = useTranslation();
  const [
    versionStatus,
    versionErrorMessage,
    isVersionInvalid,
    setVersionInputFieldState,
    invalidateInputFieldState,
  ] = useInputField();

  const [
    commentStatus,
    commentErrorMessage,
    isCommentInvalid,
    setCommentInputFieldState,
    invalidateCommentFieldState,
  ] = useOptionalInputField();

  useEffect(() => {
    invalidateInputFieldState();
    invalidateCommentFieldState();
    if (props.selection.length === 1)
      props.fetchVersions(props.selection[0]).then((items: Item[]) => {
        setVersionNumber('');
        setComment('');
        setVersions(items.map(item => item.version));
        setLatestVersion(
          items && items.length !== 0 ? items[0].version : '1.0'
        );
        setIsFetchingVersion(false);
      });
    if (
      versionNumberInputRef != null &&
      versionNumberInputRef.current != null
    ) {
      //@ts-ignore
      versionNumberInputRef.current.focus();
    }
  }, [props.isOpen, isFetchingVersion]);

  const validateNumber = (number: string) => {
    if (!number || !number.trim()) {
      setVersionInputFieldState('error', t('Empty Version Error Label'));
    } else if (number.length > 50) {
      setVersionInputFieldState(
        'error',
        t('Version Name Too Long Error Label')
      );
    } else if (versions.some(version => version.trim() === number.trim())) {
      setVersionInputFieldState(
        'error',
        t('Version Already Exists Error Label')
      );
    } else {
      setVersionInputFieldState('success', '');
    }
  };

  const validateComment = (comment: string) => {
    if (comment && comment.length > 250) {
      setCommentInputFieldState('error', t('Version Comment Too Long'));
    } else {
      setCommentInputFieldState('success', '');
    }
  };

  const handleVersionChange = (event: any) => {
    const version = event.target.value as string;
    validateNumber(version);
    setVersionNumber(version);
  };

  const handleCommentChange = (event: any) => {
    const comment = event.target.value as string;
    validateComment(comment);
    setComment(comment);
  };

  const createVersion = () => {
    props.createVersion(props.selection[0], versionNumber.trim(), comment);
    close();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.charCode == 13) {
      // Enter
      const isInputInvalid = isVersionInvalid || isCommentInvalid;
      if (!isInputInvalid) {
        createVersion();
      }
    }
  };

  const close = () => {
    setVersionNumber('');
    setComment('');
    setVersionInputFieldState('', '');
    setCommentInputFieldState('', '');
    props.onClose();
  };
  return (
    <Modal
      modalRootId={'modal-root'}
      dismissible={true}
      isOpen={props.isOpen}
      title={t('Create a version')}
      closeHandle={close}
      isPrimaryDisabled={isVersionInvalid || isCommentInvalid}
      primaryButtonHandle={createVersion}
      primaryButtonLabel={t('OK')}
      secondaryButtonHandle={close}
      secondaryButtonLabel={t('Cancel')}
    >
      {isFetchingVersion ? (
        <Spinner style={{ alignSelf: 'center' }} indeterminate={true} />
      ) : (
        <div className={'ps-modal-content'}>
          <LabeledInput
            label={t('Version Number')}
            data-testid="version-input-field"
            message={versionErrorMessage}
            status={versionStatus}
            onChange={handleVersionChange}
            value={versionNumber}
            ref={versionNumberInputRef}
            placeholder={
              latestVersion != null && latestVersion !== ''
                ? latestVersion === '1.0' && versions.length == 0
                  ? t('Version Suggested Label') + ' ' + latestVersion
                  : t('Version Last Label') + ' ' + latestVersion
                : ''
            }
            onKeyPress={onKeyPress}
          />
          <LabeledTextarea
            className={'not-resizable'}
            label={t('Comment')}
            onChange={handleCommentChange}
            value={comment}
            message={commentErrorMessage}
            status={commentStatus}
          />
        </div>
      )}
    </Modal>
  );
};

export const mapStateToProps = (store: any) => {
  return {
    selection: store.selection.selectedItems,
  };
};

export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ fetchVersions, createVersion }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVersionModal);
