import * as React from 'react';
export const SvgPptx = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path opacity={0.4} fill="#231F20" d="M3 0v16h13V4.6L11.4 0z" />
    <path fill="#FFF" d="M4 15V1h7l4 4v10z" />
    <g fill="#F5BA9D">
      <path d="M5 11h9v1H5zM5 13h9v1H5z" />
    </g>
    <path fill="#FFF" d="M6 10h1v5H6z" />
    <path fill="#909090" d="M9 4C7.3 4 6 5.3 6 7s1.3 3 3 3 3-1.3 3-3H9V4z" />
    <path fill="#F5BA9D" d="M9 4v3h3c0-1.7-1.3-3-3-3z" />
    <path fill="#FFF" d="M11 5V1l4 4z" />
    <path opacity={0.4} fill="#231F20" d="M11 5V1h-1v5h5V5z" />
    <g fillRule="evenodd" clipRule="evenodd">
      <path fill="#FFF" d="M4 2.5v11.9l4 .6V2z" />
      <path fill="#D04727" d="M0 4l7-1v11l-7-1.1V4z" />
      <path
        fill="#FFF"
        d="M4 6H2.5c-.3 0-.5.2-.5.5V11h1V9h1c.3 0 1 0 1-1V7c0-1-.7-1-1-1zm0 2H3V7h1v1z"
      />
    </g>
  </svg>
);
export default SvgPptx;
