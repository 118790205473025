// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bnt-audit-trail-form {
  display: flex;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%; }
  .bnt-audit-trail-form .bnt-audit-loading-spinner {
    padding-top: 50px;
    margin: auto; }
`, "",{"version":3,"sources":["webpack://./src/components/auditTrail/formAuditTrail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,0BAA0B;EAC1B,sBAAsB;EACtB,WAAW,EAAE;EACb;IACE,iBAAiB;IACjB,YAAY,EAAE","sourcesContent":[".bnt-audit-trail-form {\n  display: flex;\n  -moz-flex-direction: column;\n  -ms-flex-direction: column;\n  flex-direction: column;\n  width: 100%; }\n  .bnt-audit-trail-form .bnt-audit-loading-spinner {\n    padding-top: 50px;\n    margin: auto; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
