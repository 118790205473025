import './detailInfo.css';

import React from 'react';
import { Item } from '../../entities/entities';
import { PanelSection } from '@bentley/bwc-react/innersource/InfoPanel/PanelSection/PanelSection';
import { PanelProperty } from '@bentley/bwc-react/innersource/InfoPanel/PanelProperty/PanelProperty';
import {
  getFormattedFileSize,
  getFormattedDateAndTime,
} from '../../services/dataFormatters';
import { containsFile } from '../../services/permissionsService';
import { useTranslation } from 'react-i18next';
import { Button } from '@bentley/bwc-react/core';
import SvgTechnicalPreviewBadge from '@bentley/bwc-react/icons/TechnicalPreviewBadge';

export interface DetailInfoProps {
  toggleManageVersionsModal: () => void;
  selectedItems: Item[];
  isVersioningEnabled: boolean;
}

const DetailInfo: React.FunctionComponent<DetailInfoProps> = (
  props: any
): any => {
  const { t } = useTranslation();

  return (
    <PanelSection>
      {containsFile(props.selectedItems) && (
        <PanelProperty
          key={'size'}
          label={'Size'}
          value={getFormattedFileSize(props.selectedItems[0].size)}
        />
      )}
      <PanelProperty
        key={'dateCreated'}
        label={t('Date Created')}
        value={getFormattedDateAndTime(
          new Date(props.selectedItems[0].createdTimeStamp)
        )}
      />
      <PanelProperty
        key={'lastModified'}
        label={t('Last Modified')}
        value={getFormattedDateAndTime(
          new Date(props.selectedItems[0].modifiedTimeStamp)
        )}
      />

      {props.isVersioningEnabled && containsFile(props.selectedItems) && (
        <Button
          styleType="hollow"
          onClick={props.toggleManageVersionsModal}
          className="view-version-button"
          style={{ padding: '0px', display: 'inline-flex', minWidth: '118px' }}
        >
          <div
            style={{
              paddingTop: '7px',
              paddingBottom: '7px',
              paddingLeft: '10px',
              minWidth: '90px',
              textAlign: 'center',
            }}
          >
            {t('View versions')}
          </div>
          <SvgTechnicalPreviewBadge style={{ height: '16px' }} />
        </Button>
      )}
    </PanelSection>
  );
};

export default DetailInfo;
