import React from 'react';
import { SvgGenericErrorPage } from '../icons/SvgGenericErrorPage';
import './genericErrorPage.css';

export type GenericErrorPageProps = {
  title?: string;
  caption?: string;
  content?: JSX.Element[] | JSX.Element;
} & React.HTMLAttributes<HTMLDivElement>;

export const GenericErrorPage = (props: GenericErrorPageProps) => {
  const { title, caption, content, ...rest } = props;

  return (
    <div className="fit-to-parent center-content" {...rest}>
      <div className="fullscreen-error-page">
        <SvgGenericErrorPage className="error-svg" data-testid="error-svg" />

        {title && <h1>{title}</h1>}
        {caption && <span>{caption}</span>}
        {content && content}
      </div>
    </div>
  );
};
