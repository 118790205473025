import {
  SET_RBAC_INSTANCE_PERMISSIONS,
  INVALIDATE_RBAC_INSTANCE_PERMISSIONS,
  SET_RBAC_FOLDER_TYPE,
} from './actionConstants';
import { defaultOptions } from '../services/fetchHelper';
import { logger } from '../services/logger';

export const setFolderType = (folderType: string) => {
  return {
    type: SET_RBAC_FOLDER_TYPE,
    payload: folderType,
  };
};

export const fetchRbacFolderType = () => {
  return (dispatch: any, getState: any): Promise<any> => {
    const url = `${
      getState().urls.rbacUrl
    }/v2.4/Repositories/BentleyCONNECT--Main/RBAC/ObjectType?$filter=Name eq 'Folder_RoleType' and ServiceGPRId eq 2567`;
    logger.logInfo(`Started rbac folder type: ${url}`, [url]);
    return fetch(url, { ...defaultOptions() })
      .then(response => response.json())
      .then(response => {
        if (response && response.instances) {
          logger.logInfo('Finished rbac folder type');
          dispatch(setFolderType(response.instances[0].instanceId));
        }
      })
      .catch(err => {
        logger.logError(`Failed rbac folder type: ${err}`, [err]);
      });
  };
};

const getInstancePermissionUrl = (
  baseUrl: string,
  userId: string,
  folderType: string,
  accessOriginsList: string
): string =>
  `${baseUrl}/v2.4/Repositories/BentleyCONNECT--Main/RBAC/User/${userId}/Object?$select=*,ObjectPermission-forward-Permission.*&$filter=TypeId eq '${folderType}' and $id in ['${accessOriginsList}']`;

const updatePermissions = (
  responseInstances: any,
  readPermissions: string[],
  writePermissions: string[],
  deletePermissions: string[],
  storeInstancePermissions: any
) => {
  responseInstances.map((instance: any) => {
    if (instance.relationshipInstances) {
      instance.relationshipInstances.map((relInstance: any) => {
        if (
          ['SHARE_READ', 'SHARE_WRITE', 'SHARE_DELETE'].includes(
            relInstance.relatedInstance.instanceId
          ) &&
          !storeInstancePermissions.readPermissions.includes(
            instance.instanceId
          ) &&
          !readPermissions.includes(instance.instanceId)
        )
          readPermissions.push(instance.instanceId);

        if (
          ['SHARE_WRITE', 'SHARE_DELETE'].includes(
            relInstance.relatedInstance.instanceId
          ) &&
          !storeInstancePermissions.writePermissions.includes(
            instance.instanceId
          ) &&
          !writePermissions.includes(instance.instanceId)
        )
          writePermissions.push(instance.instanceId);

        if (
          ['SHARE_DELETE'].includes(relInstance.relatedInstance.instanceId) &&
          !storeInstancePermissions.deletePermissions.includes(
            instance.instanceId
          ) &&
          !deletePermissions.includes(instance.instanceId)
        )
          deletePermissions.push(instance.instanceId);
      });
    }
  });
};
export const cacheInstancePermissions = (
  readPermissions: string[],
  writePermissions: string[],
  deletePermissions: string[]
) => {
  return {
    type: SET_RBAC_INSTANCE_PERMISSIONS,
    payload: {
      readPermissions,
      writePermissions,
      deletePermissions,
    },
  };
};

export const invalidateInstancePermissionsCache = () => {
  return {
    type: INVALIDATE_RBAC_INSTANCE_PERMISSIONS,
  };
};

export interface InstancePermissions {
  instanceId: string;
  permissions: string[];
}

export const fetchInstancePermissions = (accessOrigins: string[]) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    if (accessOrigins.length > 0) {
      const readPermissions: string[] = [];
      const writePermissions: string[] = [];
      const deletePermissions: string[] = [];

      let mergedResponse: string[] = [];
      let index = 0;
      while (index < accessOrigins.length) {
        const batchAccessOrigins = accessOrigins.splice(index, index + 20);
        const accessOriginsList = batchAccessOrigins.join("','");
        const url = getInstancePermissionUrl(
          getState().urls.rbacUrl,
          getState().user.id,
          getState().instancePermissions.folderType,
          accessOriginsList
        );

        logger.logInfo(`Started fetching instance permissions: ${url}`, [url]);
        await fetch(url, { ...defaultOptions() })
          .then(response => response.json())
          .then(response => {
            logger.logInfo(`Finished fetching instance permissions: ${url}`, [
              url,
            ]);
            if (
              response &&
              response.instances &&
              response.instances.length > 0
            ) {
              mergedResponse = mergedResponse.concat(response.instances);
            }
          });
      }

      const storeInstancePermissions = getState().instancePermissions;
      if (mergedResponse.length > 0) {
        updatePermissions(
          mergedResponse,
          readPermissions,
          writePermissions,
          deletePermissions,
          storeInstancePermissions
        );

        dispatch(
          cacheInstancePermissions(
            readPermissions,
            writePermissions,
            deletePermissions
          )
        );
      } else {
        return Promise.resolve(null);
      }
    } else {
      return Promise.resolve(null);
    }
  };
};
