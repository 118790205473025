import {
  SET_SELECTION,
  INVALIDATE_SELECTION,
} from '../actions/actionConstants';
import { Item } from '../entities/entities';

export interface SelectionState {
  selectedItems: Item[];
  selectedItemsIds: string[];
}
const initialState: SelectionState = {
  selectedItems: [],
  selectedItemsIds: [],
};

export const SelectionReducer = (
  state: SelectionState = initialState,
  action: any
): any => {
  switch (action.type) {
    case SET_SELECTION:
      return action.payload;
    case INVALIDATE_SELECTION:
      return {
        selectedItems: [],
        selectedItemsIds: [],
      };
    default:
      return state;
  }
};
