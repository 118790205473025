import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProjectShare from './projectShare';
import * as initializationActions from './actions/initializationActions';
import { setUser, setIsAdminInOrg } from './actions/userActions';
import { fetchProjectPermissions } from './actions/projectPermissionsActions';
import { fetchRbacFolderType } from './actions/instancePermissionsActions';
import {
  fetchFolderData,
  fetchFolder,
  setIsLoading,
  setError,
  invalidateData,
  setEmptyTableLabel,
} from './actions/dataActions';
import { fetchSyncedFolders } from './actions/synchronizeFoldersActions';
import {
  initializeBreadcrumbs,
  setRootFolderName,
} from './actions/breadcrumbsActions';
import { setFeatureFlagsForUser } from './actions/featuresActions';
import { Item } from './entities/entities';
import { setBreadcrumbsFromItemPath } from './services/utillities';
import { AppState } from './store/configureStore';
import {
  fetchPermissionRoles,
  fetchUserRoles,
} from './actions/setFolderAccessActions';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { hasAdminPermissions } from './services/permissionsService';
import { postToImodelHubRequest } from './services/postToImodelHubService';
import { toast, toastType } from './services/toastService';
import Tour from './components/onboardingMessages/shareTour';
import { ShareTour } from './services/shareTour';
import { ConnectedShareAppProps } from '../index';
import { logger } from './services/logger';

export type StateProps = {
  isInitialized: boolean;
  currentFolderIdFromState: string;
  currentProjectIdFromState: string;
  contextFromState: string;
};

export const ConnectedProjectShareApp: React.FunctionComponent<ConnectedShareAppProps> = (
  props: any
) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.language && i18n.language != props.language) {
      i18n.changeLanguage(props.language);
    }
  }, [props.language]);

  const projectId: string = props.projectId as string;
  const folderId: string = props.folderId as string;
  const itemId: string = props.itemId as string;
  const emptyTableLabel =
    props.emptyTableLabel != null
      ? props.emptyTableLabel
      : t('There are no files available');

  useEffect(() => {
    props.setEmptyTableLabel(emptyTableLabel);
    props.setUser({
      accessToken: props.user.access_token,
      id: props.user.profile.sub,
      isAdmin:
        props.contextFromState !== null &&
        hasAdminPermissions(props.user, props.contextFromState.UltimateRefId),
    });
  }, [props.user]);

  useEffect(() => {
    handleMount();
  }, [props.projectId]);

  async function handleMount() {
    props.setIsLoading(true);

    if (
      !props.isInitialized ||
      props.currentProjectIdFromState != props.projectId
    ) {
      await fetchInitialData();
      props.setIsInitialized(true);
    } else {
      await refreshTable();
    }

    props.setIsLoading(false);
  }

  async function refreshTable() {
    props.invalidateData();
    await props.fetchFolderData(props.currentFolderIdFromState);
  }

  async function fetchInitialData() {
    props.setProjectId(props.projectId);
    props.setUrls(props.urls);
    props.setRootFolderName(props.rootFolderName || t('Home'));
    props.setLinkFormat(
      props.linkFormat ? props.linkFormat : '{projectId}/{folderId}/{itemId}'
    );
    props.setGPRId(props.GPRId ? props.GPRId : '2567');

    let context = null;
    try {
      context = await props.fetchProjectContext();
    } catch {
      const errorKey = 'Project Access Denied Error';

      props.setError('Forbidden', errorKey);
      return;
    }

    const permissions = await props.fetchProjectPermissions();
    if (!permissions.rbacRead) {
      const errorKey = `You don't have 'Read' RBAC permission`;
      props.setError('Forbidden', errorKey);
      return;
    }
    const features = await props.setFeatureFlagsForUser(
      props.user,
      props.buddiRegion
    );

    props.setIsAdminInOrg(
      hasAdminPermissions(props.user, context ? context.UltimateRefId : '')
    );

    await props.fetchRbacFolderType();

    const backgroundPromises = [];
    backgroundPromises.push(props.fetchSyncedFolders());
    if (features.folderAccess) {
      backgroundPromises.push(props.fetchPermissionRoles());
      backgroundPromises.push(props.fetchUserRoles());
    }

    const currentFolder = folderId ? folderId : projectId;
    props.setCurrentFolderId(currentFolder);

    const currentFolderItems = await props.fetchFolderData(currentFolder);
    if (itemId) {
      let selection: Item[] = [];
      if (itemId === 'selection') {
        const selectionJsonString = sessionStorage.getItem('selection');
        if (selectionJsonString) {
          selection = JSON.parse(selectionJsonString);
          sessionStorage.removeItem('selection');
          backgroundPromises.push(
            postToImodelHubRequest(selection.map(i => i.instanceId))
          );
        } else {
          toast(toastType.error, i18n.t('Post Imodel Failed'));
        }
      } else {
        selection = [
          currentFolderItems.find((item: Item) => item.instanceId === itemId),
        ];
        if (!selection[0]) {
          logger.logInfo(
            "Didn't get the data in top 50.... Searching the rest"
          );
          const allDataRecords = await props.fetchFolderData(
            currentFolder,
            false,
            true,
            undefined,
            true
          );
          selection = [
            allDataRecords.find((item: Item) => item.instanceId === itemId),
          ];
        }
      }

      setBreadcrumbsFromItemPath(selection);
    } else if (folderId) {
      const item = await props.fetchFolder(folderId);
      setBreadcrumbsFromItemPath([item]);
    } else {
      setBreadcrumbsFromItemPath(undefined);
    }

    await Promise.all(backgroundPromises);
    props.setIsInitialized(true);
  }

  return (
    <>
      <ProjectShare />
      <Tour
        tour={ShareTour}
        productSettingsServiceUrl={props.urls.productSettingsServiceUrl}
        accessToken={props.user.access_token}
      />
    </>
  );
};

export const mapStateToProps = (store: AppState): StateProps => {
  return {
    isInitialized: store.navigation.isInitialized,
    currentFolderIdFromState: store.navigation.currentFolderId,
    currentProjectIdFromState: store.navigation.projectId,
    contextFromState: store.navigation.context,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      ...initializationActions,
      setUser,
      fetchProjectPermissions,
      fetchRbacFolderType,
      fetchFolderData,
      fetchFolder,
      fetchPermissionRoles,
      fetchUserRoles,
      fetchSyncedFolders,
      initializeBreadcrumbs,
      setRootFolderName,
      setFeatureFlagsForUser,
      setIsAdminInOrg,
      setIsLoading,
      setError,
      invalidateData,
      setEmptyTableLabel,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedProjectShareApp);
