import React from 'react';

//bwc-react
import { SvgFolder } from '@bentley/bwc-react/icons/Folder';
import { SvgDocument } from '@bentley/bwc-react/icons/Document';
import { SvgAmim } from '@bentley/bwc-react/icons/file-types/Amim';
import { SvgDgnDb } from '@bentley/bwc-react/icons/file-types/DgnDb';
import { SvgDgnDb2 } from '@bentley/bwc-react/icons/file-types/DgnDb2';
import { SvgAutocad } from '@bentley/bwc-react/icons/file-types/Autocad';
import { SvgImage } from '@bentley/bwc-react/icons/file-types/Image';
import { SvgMicrostation } from '@bentley/bwc-react/icons/file-types/Microstation';
import { SvgMism } from '@bentley/bwc-react/icons/file-types/Mism';
import { SvgPdf } from '@bentley/bwc-react/icons/file-types/Pdf';
import { SvgPdfMarkup } from '@bentley/bwc-react/icons/file-types/PdfMarkup';
import { SvgPpt } from '@bentley/bwc-react/icons/file-types/Ppt';
import { SvgRevit } from '@bentley/bwc-react/icons/file-types/Revit';
import { SvgTxt } from '@bentley/bwc-react/icons/file-types/Txt';
import { SvgVideo } from '@bentley/bwc-react/icons/file-types/Video';
import { SvgWord } from '@bentley/bwc-react/icons/file-types/Word';
import { SvgXls } from '@bentley/bwc-react/icons/file-types/Xls';
import { SvgVisio } from '@bentley/bwc-react/icons/file-types/Visio';
import { SvgLocked } from '../icons/Locked';
import { SvgCheckedout } from '../icons/Checkedout';
import { SvgSecureFolderBadge } from '../icons/SecureFolderBadge';
import { store } from '../../store/configureStore';
import {
  containsLockedByOther,
  containsLockedFile,
} from '../../services/permissionsService';
import { Item } from '../../entities/entities';
import SvgPptx from '../icons/Pptx';
import SvgWorddocx from '../icons/Worddocx';
import SvgXlsx from '../icons/Xlsx';
import { getFileType } from '../../services/utillities';

export const getIconByType = (type: string) => {
  store.getState().user.id;
  switch (type) {
    case 'Folder':
      return <SvgFolder className="bwc-icons-medium bwc-icon" />;
    case 'Amim':
      return <SvgAmim className="bwc-icons-medium bwc-icon" />;
    case 'i-model':
      return <SvgDgnDb2 className="bwc-icons-medium bwc-icon" />;
    case 'DgnDb':
      return <SvgDgnDb className="bwc-icons-medium bwc-icon" />;
    case 'Dgn-Db':
      return <SvgDgnDb2 className="bwc-icons-medium bwc-icon" />;
    case 'Text':
      return <SvgTxt className="bwc-icons-medium bwc-icon" />;
    case 'Image':
      return <SvgImage className="bwc-icons-medium bwc-icon" />;
    case 'AutoCad':
      return <SvgAutocad className="bwc-icons-medium bwc-icon" />;
    case 'Mism':
      return <SvgMism className="bwc-icons-medium bwc-icon" />;
    case 'Pdf':
      return <SvgPdf className="bwc-icons-medium bwc-icon" />;
    case 'PowerPoint':
      return <SvgPpt className="bwc-icons-medium bwc-icon" />;
    case 'PowerPointX':
      return <SvgPptx className="bwc-icons-medium bwc-icon" />;
    case 'Video':
      return <SvgVideo className="bwc-icons-medium bwc-icon" />;
    case 'Word':
      return <SvgWord className="bwc-icons-medium bwc-icon" />;
    case 'WordX':
      return <SvgWorddocx className="bwc-icons-medium bwc-icon" />;
    case 'Excel':
      return <SvgXls className="bwc-icons-medium bwc-icon" />;
    case 'ExcelX':
      return <SvgXlsx className="bwc-icons-medium bwc-icon" />;
    case 'Document':
      return <SvgDocument className="bwc-icons-medium bwc-icon" />;
    case 'Revit':
      return <SvgRevit className="bwc-icons-medium bwc-icon" />;
    case 'MicroStation':
      return <SvgMicrostation className="bwc-icons-medium bwc-icon" />;
    case 'Markup':
      return <SvgPdfMarkup className="bwc-icons-medium bwc-icon" />;
    case 'Visio':
      return <SvgVisio className="bwc-icons-medium bwc-icon" />;

    default:
      return <SvgDocument className="bwc-icons-medium bwc-icon" />;
  }
};

export interface IconProperties {
  iconType: string;
  isLocked: boolean;
  isCheckedOut: boolean;
  isSecured: boolean;
}

export const getIcon = (item: any, icon: boolean) => {
  const iconProperties: IconProperties = getItemIconProperties(item);
  return (
    <span className="bwc-icons-medium">
      {icon &&
        getIconByType(
          iconProperties.iconType != 'Folder'
            ? getFileType(item.name)
            : 'Folder'
        )}
      {iconProperties.isLocked && (
        <SvgLocked className="bwc-icons-small icon-overlay" />
      )}
      {iconProperties.isCheckedOut && (
        <SvgCheckedout className="bwc-icons-small icon-overlay" />
      )}
      {iconProperties.isSecured && (
        <SvgSecureFolderBadge className="bwc-icons-small folder-access-badge" />
      )}
    </span>
  );
};

export const getItemIconProperties = (item: Item) => {
  const lockedByOther = containsLockedByOther([item]);
  const isLocked = lockedByOther;
  const isCheckedOut = !lockedByOther && containsLockedFile([item]);
  const isSecured =
    item.accessOrigin == item.instanceId && item.contentType != 'folder';
  const parts = item.name.split('.');
  const extension = parts[parts.length - 1];
  const iconType =
    extension == 'docx' || extension == 'pptx' || extension == 'xlsx'
      ? item.contentType + 'X'
      : item.contentType;
  return {
    iconType: iconType,
    isLocked: isLocked,
    isCheckedOut: isCheckedOut,
    isSecured: isSecured,
  } as IconProperties;
};
