import React from 'react';
import './userIcon.css';

export function getInitials(fullName: string): string {
  const names = fullName
    .split(' ')
    .map(name => name.charAt(0).toLocaleUpperCase());
  return names.length > 1 ? names[0] + names[names.length - 1] : names[0];
}

export type UserIconSize = 'medium' | 'large';

export type UserIconProps = {
  fullname: string;
  image?: string;
  size?: UserIconSize;
  backgroundColor?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

export const UserIcon = (props: UserIconProps) => {
  const {
    fullname,
    image,
    size = 'medium',
    backgroundColor,
    className,
    ...rest
  } = props;

  const _className =
    'bnt-user-icon-container ' +
    (size === 'medium' ? 'bnt-user-icon-medium' : 'bnt-user-icon-large') +
    (className ? ' ' + className : '');

  const colorStyle = backgroundColor
    ? { backgroundColor: backgroundColor }
    : {};

  return (
    <span data-testid={_className} className={_className} {...rest}>
      <span style={colorStyle}>{getInitials(fullname)}</span>
      {image ? <img src={image} alt={fullname} /> : null}
    </span>
  );
};

export default UserIcon;
