import { Button, Loader, Modal } from '@bentley/bwc-react/core';
// Moving TableBreadCrumbs from bwc to test workfolw with IE and fix bugs to be assured that functionality will work with dropdown
import { TableBreadCrumbs } from '@bentley/bwc-react/core/TableComponents/TableBreadCrumbs/TableBreadCrumbs';
import SvgAdd from '@bentley/bwc-react/icons/Add';
import SvgErrorHollow from '@bentley/bwc-react/icons/status/ErrorHollow';
import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  useRef,
} from 'react';
import { IBreadcrumb, useBreadcrumb } from '../../hooks/useBreadcrumb';
import './folderSelectDialog.css';
import { connect } from 'react-redux';
import { FolderNameInput } from '../inputs/folderNameInput';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getIconByType, getItemIconProperties } from '../table/itemIcon';
import { Item } from '../../entities/entities';
import { getFileType } from '../../services/utillities';
import { fetchFolderData } from '../../actions/dataActions';
import { bindActionCreators } from 'redux';

interface IFolderSelectDialogProps<T extends IBreadcrumb> {
  onClose: () => void;
  createNewFolder?: (parent: T, name: string) => Promise<Item>;
  dialogTitle: string;
  getSubfolders: (currentFolder: T) => Promise<Item[]>;
  initialBreadcrumbs: IBreadcrumb[];
  onSave: (targetFolder: T) => any;
  saveLabel: string;
  currentFolderId: string;
  selectedItemsIds: string[];
  fetchFolderData: (
    folderId: string,
    onlyFolders?: boolean,
    doReceive?: boolean,
    page?: number
  ) => Promise<Item[]>;
}

export const FolderSelectDialog: React.FunctionComponent<any> = <
  T extends IBreadcrumb
>(
  props: IFolderSelectDialogProps<T>
): JSX.Element => {
  const {
    onClose,
    createNewFolder,
    dialogTitle,
    getSubfolders,
    initialBreadcrumbs,
    onSave,
    saveLabel,
    currentFolderId,
    selectedItemsIds,
    fetchFolderData,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [targetFolder, setTargetFolder] = useState<T>(
    initialBreadcrumbs[initialBreadcrumbs.length - 1] as T
  );
  const [subFolders, setSubFolders] = useState<T[]>([] as T[]);
  const [newFolderState, setNewFolderState] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);

  const { t } = useTranslation();

  const breadcrumbManager = useBreadcrumb(initialBreadcrumbs);

  const tableAreaRef = useRef<HTMLDivElement>(null);

  const currentFolder = (): T => {
    return breadcrumbManager.breadcrumbs[
      breadcrumbManager.breadcrumbs.length - 1
    ] as T;
  };

  const mapItemToBreadcrumb = (item: Item) =>
    ({
      name: item.name || '',
      className: item.className,
      iconProperties: getItemIconProperties(item),
      instanceId: item.instanceId,
    } as T);

  useEffect(() => {
    const fetchNextPageFolderData = async () => {
      const folderData = await fetchFolderData(
        targetFolder.instanceId,
        true,
        false,
        currentPage
      );

      if (folderData.length == 0) {
        setIsLastPage(true);
      }

      const filteredFolders = folderData.filter(
        folder => selectedItemsIds.indexOf(folder.instanceId) === -1
      );

      const nextPageSubFolders = filteredFolders.map(mapItemToBreadcrumb);
      setSubFolders([...subFolders, ...nextPageSubFolders]);
    };

    if (currentPage !== 0) {
      fetchNextPageFolderData();
    }
  }, [currentPage]);

  const handleScroll = (event: any) => {
    const node = event.target;
    const bottom = node.scrollHeight - node.scrollTop - node.clientHeight < 1;

    if (bottom && !isLastPage) {
      setCurrentPage(currentPage => currentPage + 1);
    }
  };

  useEffect(() => {
    tableAreaRef?.current
      ?.getElementsByClassName('fsd-folder-list')[0]
      .addEventListener('scroll', handleScroll);

    return () => {
      tableAreaRef?.current
        ?.getElementsByClassName('fsd-folder-list')[0]
        .removeEventListener('scroll', handleScroll);
    };
  }, [!isLoading && !isLastPage]);

  useEffect(() => {
    const fetchSubfolders = async (folder: T): Promise<void> => {
      setCurrentPage(0);
      setIsLastPage(false);
      setIsError(false);
      setIsLoading(true);
      try {
        const subfolderItems = await getSubfolders(folder);
        const subfolders = subfolderItems.map(mapItemToBreadcrumb);
        setSubFolders(subfolders);
      } catch {
        setIsError(true);
      }
      setIsLoading(false);
    };

    setTargetFolder(currentFolder());
    fetchSubfolders(currentFolder());
  }, [breadcrumbManager.breadcrumbs]);

  const onRowClick = (folder: T): void => {
    if (targetFolder.instanceId == folder.instanceId) {
      setTargetFolder(currentFolder());
    } else {
      setTargetFolder(folder);
    }
  };

  const onNavigationClick = (folder: T, e: React.SyntheticEvent): void => {
    e.stopPropagation(); // Prevent additional row click
    setTargetFolder(folder);
    breadcrumbManager.addBreadcrumb(folder);
  };

  const onNewFolderClick = (): void => {
    setNewFolderState(true);
  };

  const onNewFolderSave = async (name: string): Promise<void> => {
    if (createNewFolder) {
      setNewFolderState(false);
      setIsLoading(true);
      setIsError(false);
      try {
        const newFolder = await createNewFolder(currentFolder(), name);
        breadcrumbManager.addBreadcrumb(mapItemToBreadcrumb(newFolder));
      } catch {
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  const onSaveClick = (): void => {
    onClose();
    onSave(targetFolder);
  };

  return (
    <Modal
      modalRootId="modal-root"
      data-testid="FolderNavigationModal"
      title={dialogTitle}
      isOpen={true}
      closeHandle={onClose}
      dismissible={true}
      primaryButtonLabel={saveLabel}
      primaryButtonHandle={newFolderState ? undefined : onSaveClick}
      isPrimaryDisabled={
        isError ||
        isLoading ||
        newFolderState ||
        currentFolderId === targetFolder.instanceId
      }
      secondaryButtonLabel={t('Cancel')}
      secondaryButtonHandle={newFolderState ? undefined : onClose}
    >
      <div className="fsd">
        {isError && <Error />}
        {newFolderState && (
          <NewFolder
            close={() => setNewFolderState(false)}
            submit={onNewFolderSave}
          />
        )}
        {!isError && !newFolderState && isLoading && <Loading />}
        {!isError && !newFolderState && !isLoading && (
          <div ref={tableAreaRef}>
            <TableBreadCrumbs
              depth={breadcrumbManager.tableBreadcrumbs.depth}
              labels={breadcrumbManager.tableBreadcrumbs.labels}
              values={breadcrumbManager.tableBreadcrumbs.values}
              jumpTo={breadcrumbManager.jumpTo}
            />
            <FoldersList
              subFolders={subFolders}
              targetFolder={targetFolder}
              onRowClick={onRowClick}
              onNavigationClick={onNavigationClick}
              t={t}
            />
            {createNewFolder && (
              <NewButton
                onNewFolderClick={onNewFolderClick}
                disabled={isLoading || isError}
                t={t}
              />
            )}
            <div className="fsd-selected-folder">
              {t('Selected Folder')}: {targetFolder.name}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const Error = (): JSX.Element => {
  return (
    <div className="fsd-empty-state">
      <SvgErrorHollow />
      <div className="empty-text">An error has occurred.</div>
    </div>
  );
};

const Loading = (): JSX.Element => {
  return (
    <div className="fsd-loader">
      <Loader size="large" data-testid="FSDLoader" />
    </div>
  );
};

interface IFoldersListProps<T extends IBreadcrumb> {
  onNavigationClick: (folder: T, e: React.SyntheticEvent) => void;
  onRowClick: (folder: T, e: React.SyntheticEvent) => void;
  subFolders: T[];
  targetFolder: T;
  t: TFunction;
}

const FoldersList = <T extends IBreadcrumb>({
  onNavigationClick,
  onRowClick,
  subFolders,
  targetFolder,
  t,
}: IFoldersListProps<T>): JSX.Element => {
  return (
    <ul className="fsd-folder-list" role="menu">
      {subFolders.length ? (
        subFolders.map(folder => (
          <li
            className={`fsd-folder-row ${
              targetFolder.instanceId == folder.instanceId
                ? 'fsd-folder-row-selected'
                : ''
            }`}
            key={folder.instanceId}
            onClick={e => onRowClick(folder, e)}
            onKeyDown={e => onRowClick(folder, e)}
            role="menuitem"
          >
            {getIconByType(
              folder.iconProperties.iconType != 'Folder'
                ? getFileType(folder.name)
                : 'Folder'
            )}
            <span
              className="fsd-folder-row-name"
              title={folder.name}
              onClick={e => onNavigationClick(folder, e)}
              onKeyDown={e => onNavigationClick(folder, e)}
              role="button"
              tabIndex={0}
            >
              {folder.name}
            </span>
          </li>
        ))
      ) : (
        <div className="fsd-empty-state">
          <div className="empty-text">{t('There Are No Folders')}</div>
        </div>
      )}
    </ul>
  );
};

interface INewButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onNewFolderClick: () => void;
  t: TFunction;
}

const NewButton = ({ onNewFolderClick, t }: INewButtonProps): JSX.Element => {
  return (
    <Button
      styleType="hollow"
      title={t('Add New Folder')}
      onClick={onNewFolderClick}
      className="fsd-new-folder-btn bwc-buttons-blue"
    >
      <SvgAdd className="fsd-button-icon" />
      {t('Add New Folder') as string}
    </Button>
  );
};

interface INewFolderProps {
  close: () => void;
  submit: (name: string) => {};
}

const NewFolder = ({ close, submit }: INewFolderProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [isInputInvalid, setIsInputInvalid] = useState<boolean>(true);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.charCode == 13) {
      // Enter
      if (!isInputInvalid) {
        inputComplete();
      }
    }
  };

  const inputComplete = (): void => {
    submit(newFolderName);
  };

  return (
    <>
      <FolderNameInput
        className="fsd-new-folder-label"
        htmlFor="NewFolderInput"
        label={t('Folder name')}
        data-testid="new-folder-name-input"
        value={newFolderName}
        onKeyPress={onKeyPress}
        setFolderName={setNewFolderName}
        setIsInputInvalid={setIsInputInvalid}
        forwardedRef={inputRef}
      />
      <div className="bwc-modal-footer">
        <div className="fsd-new-folder-btns">
          <Button
            styleType="blue"
            disabled={isInputInvalid}
            onClick={inputComplete}
            className="bwc-modal-footer-button"
          >
            {t('Save') as string}
          </Button>
          <Button
            styleType="hollow"
            onClick={close}
            className="bwc-modal-footer-button"
          >
            {t('Cancel') as string}
          </Button>
        </div>
      </div>
    </>
  );
};

export const mapStateToProps = (store: any) => {
  return {
    currentFolderId: store.navigation.currentFolderId,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  // @ts-ignore
  return bindActionCreators(
    {
      fetchFolderData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderSelectDialog);
