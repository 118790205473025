import { useEffect, useState } from 'react';

const applicationId = 2567;

const getProductSettingUrl = (productSettingsUrl: string, isGet: boolean) => {
  return `${productSettingsUrl}/v1.0/Application/${applicationId}/User/Setting/shareOnboarding${
    isGet ? '/flags' : ''
  }`;
};

export type OnboardingMessagesFlags = {
  Welcome: boolean;
  GetStarted: boolean;
  Navigation: boolean;
  DragAndDrop: boolean;
  MSOffice: boolean;
  FileLock: boolean;
  LocalSync: boolean;
  FolderAccess: boolean;
  AuditTrail: boolean;
};

export const useOnboardingMessages = (
  productSettingsUrl: string,
  access_token: string
): [
  OnboardingMessagesFlags,
  boolean,
  (flags: OnboardingMessagesFlags) => any
] => {
  useEffect(() => {
    const url = getProductSettingUrl(productSettingsUrl, true);
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        return null;
      })
      .then(response => {
        if (response != null) {
          setFlags({ ...flags, ...response.properties });
        }
        setIsLoading(false);
      });
  }, []);

  const [flags, setFlags] = useState<OnboardingMessagesFlags>({
    Welcome: false,
    GetStarted: false,
    Navigation: false,
    DragAndDrop: false,
    MSOffice: false,
    FileLock: false,
    LocalSync: false,
    FolderAccess: false,
    AuditTrail: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  return [flags, isLoading, setFlags];
};

export const putOnboardingFlags = (
  productSettingsServiceUrl: string,
  accessToken: string,
  flags: OnboardingMessagesFlags
) => {
  const url = getProductSettingUrl(productSettingsServiceUrl, false);
  fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: 'flags',
      namespace: 'shareOnboarding',
      properties: flags,
    }),
  });
};
