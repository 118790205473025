import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FilterSearch } from '@bentley/bwc-react/innersource/FilterSearch/FilterSearch';
import { AppState } from '../store/configureStore';
import { setSearchText } from '../actions/searchActions';
import { setBreadcrumbsFromItemPath } from '../services/utillities';
import { Item } from '../entities/entities';
import {
  fetchSearchResults,
  fetchFolderData,
  invalidatePagination,
  setIsSearch,
  fetchFolder,
} from '../actions/dataActions';
import { useTranslation } from 'react-i18next';

interface SearchProps {
  isSearch: boolean;
  projectId: string;
  currentFolderId: string;
  fetchFolderData: any;
  fetchFolder: (folderId: string) => any;
  fetchSearchResults: (searchText: string) => any;
  invalidatePagination: () => void;
  setSearchText: (text: string) => void;
  setIsSearch: (isSearchOn: boolean) => void;
  onCollapsedChange?: (newValue: boolean) => void;
  disabled?: boolean;
}

export const Search: React.FunctionComponent<SearchProps> = (
  props: SearchProps
) => {
  const { t } = useTranslation();
  const onSearch = (val: string) => {
    props.setSearchText(val);
    props.invalidatePagination();
    if (val != '') {
      props.fetchSearchResults(val);
    } else {
      props.fetchFolderData(props.currentFolderId).then(() => {
        if (props.currentFolderId === props.projectId) {
          setBreadcrumbsFromItemPath(undefined);
        } else {
          props.fetchFolder(props.currentFolderId).then((item: Item) => {
            setBreadcrumbsFromItemPath([item]);
          });
        }
      });
    }
  };

  return (
    <FilterSearch
      onSearch={onSearch}
      collapsed={!props.isSearch}
      style={{ margin: '0px' }}
      onIconClick={() => props.setIsSearch(!props.isSearch)}
      onCollapsedChange={props.onCollapsedChange}
      disabled={props.disabled}
      placeholder={t('Search placeholder')}
    />
  );
};

export const mapStateToProps = (store: AppState) => {
  return {
    isSearch: store.data.isSearch,
    projectId: store.navigation.projectId,
    currentFolderId: store.navigation.currentFolderId,
  };
};
export const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      invalidatePagination,
      fetchSearchResults,
      fetchFolderData,
      fetchFolder,
      setSearchText,
      setIsSearch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
