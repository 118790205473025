import React, { useState } from 'react';
import { ITabProps } from './tab';
import './tabs.css';

export type TabsStyle = 'default' | 'light';

export type ITabsProps = {
  tabsStyle?: TabsStyle;
  activeIndex?: number;
  onChangeTab?: (index: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const Tabs: React.FC<ITabsProps> = (props: ITabsProps) => {
  const {
    tabsStyle = 'default',
    activeIndex = 0,
    onChangeTab,
    className = '',
    ...rest
  } = props;

  const [activeTab, setActiveTab] = useState(activeIndex);

  const changeTab = (index: number) => {
    if (index != activeTab) {
      setActiveTab(index);
      if (onChangeTab) {
        onChangeTab(index);
      }
    }
  };

  const arrayTabs = () => {
    const tabs: React.ReactElement<ITabProps>[] = [];
    React.Children.forEach(props.children, (child: any) => {
      if (child.props && child.props.title) {
        tabs.push(child as React.ReactElement<ITabProps>);
      }
    });
    return tabs;
  };

  const renderTabs = () => {
    const tabs = arrayTabs().map((tab, index) => {
      return React.cloneElement(tab, {
        active: index === activeTab,
        index: index,
        changeIndex: () => changeTab(index),
        key: index,
      });
    });
    return (
      <ul className={`bnt-tabs bnt-tabs-${tabsStyle} ${className}`}>{tabs}</ul>
    );
  };

  const renderBody = () => {
    const tabs = arrayTabs();
    if (activeTab < tabs.length) {
      const tab: any = tabs[activeTab];
      if (
        tab.props &&
        tab.props.children &&
        React.isValidElement(tab.props.children)
      ) {
        return <>{tab.props.children}</>;
      }
    }
    return <></>;
  };

  return (
    <div className={`bnt-tabs-container ${className}`} {...rest}>
      {renderTabs()}
      {renderBody()}
    </div>
  );
};

export { Tab } from './tab';
