import { SET_SYNCED_FOLDERS } from '../actions/actionConstants';

export const SyncFoldersReducer = (state: string[] = [], action: any) => {
  switch (action.type) {
    case SET_SYNCED_FOLDERS:
      return action.payload;
    default:
      return state;
  }
};
