import { FeaturesState } from '../reducers/featuresReducer';
import { SET_FEATURES } from './actionConstants';
import {
  FeatureToggleClient,
  FeatureToggleUser,
} from '@bentley/feature-toggle-js';
import { User } from 'oidc-client';
import { logger } from '../services/logger';

export const setFeatureFlags = (features: FeaturesState) => {
  return {
    type: SET_FEATURES,
    payload: features,
  };
};

export const setFeatureFlagsForUser = (user: User, region: string) => {
  return (dispatch: any, getState: any) => {
    const client = new FeatureToggleClient();
    const userData: FeatureToggleUser = {
      id: user.profile.sub,
      email: user.profile.email!,
      countryIso: user.profile.usage_country_iso,
      ultimateSite: Number(user.profile.ultimate_site),
    };
    const clientId =
      region === '103'
        ? '5ac2799ec23d702c3ee4be1b'
        : region == '102'
        ? '5ac7c9928830432ceb659134'
        : '5ac27948c23d702c3ee4bde7';
    return client.initializeClient(clientId, userData).then(() => {
      const features: FeaturesState = {
        auditTrail: client.getVariation('audittrail', false),
        fileVersioning: client.getVariation('fileversioning', false),
        folderAccess: client.getVariation('folderaccess', false),
        imodelHubPublishing: client.getVariation('ImodelHubPublishing', false),
        pdfMarkup: client.getVariation('pdfmarkup', false),
        webView: client.getVariation('webview', false),
        conflictResolution: client.getVariation('conflictresolution', false),
        isWopiEnabled: client.getVariation('is-wopi-enabled', false),
        isWopiDeprecationInformationEnabled: client.getVariation(
          'wopi-deprecation-information-enabled',
          false
        ),
      };
      const stringified = JSON.stringify(features);
      logger.logInfo(`Initialized feature flags: ${stringified}`, [
        stringified,
      ]);
      dispatch(setFeatureFlags(features));
      return features;
    });
  };
};
