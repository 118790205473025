import React from 'react';
import { hasItemWritePermissions } from '../../services/permissionsService';
import { EmptyDocuments } from '../icons/EmptyDocuments';
import { NoDataGrid } from './noDataGrid';
import { useTranslation } from 'react-i18next';

export interface EmptyDocumentsGridProps {
  isLoading: boolean;
  uploadRef: any;
  currentFolderAccessOrigin: string;
}

export const EmptyDocumentsGrid: React.FunctionComponent<EmptyDocumentsGridProps> = (
  props: EmptyDocumentsGridProps
): any => {
  const { t } = useTranslation();
  return (
    <NoDataGrid
      isLoading={props.isLoading}
      image={<EmptyDocuments />}
      noFilesText={t('There Are No Files Available')}
      noFilesExplanation={t('Drag And Drop Files Here Or Browse To Upload')}
      showBrowse={true}
      onClick={() => props.uploadRef.current!.click()}
      disabled={!hasItemWritePermissions(props.currentFolderAccessOrigin)}
    />
  );
};
