import { SET_URLS } from '../actions/actionConstants';
import { Urls } from '../../index';

export const UrlReducer = (
  state: Urls = {
    ahaUrl: '',
  } as Urls,
  action: any
) => {
  switch (action.type) {
    case SET_URLS: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};
