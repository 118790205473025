/**
 * Gets browser locale in format <language>[-<territory>]
 */
export function getLocale(): string {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : (navigator as any).userLanguage ||
        navigator.language ||
        (navigator as any).browserLanguage ||
        'en';
}
