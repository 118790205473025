import React, { useEffect, useState } from 'react';
import { Redirect,useParams } from 'react-router-dom';
import { FullPageLoader } from '../../components/fullPageLoader/fullPageLoader';
import { getRedirectPath } from '../../../src/services/getLinkService';
import { useAppConfiguration } from '../../hooks/useAppConfiguration';

export const Login: React.FunctionComponent<any> = props => {
  const params = useParams<any>()
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [redirectPath, setPath] = useState<string>('');
  const [isConfigurationLoaded, manager] = useAppConfiguration();
  useEffect(() => {
    if (isConfigurationLoaded) {
      const path = getRedirectPath(
        params.projectId,
        params.folderId,
        params.itemId
      );
      setPath(path);
      if (props.user == null || props.user.expired) {
        manager.signinRedirect({
          state: { from: `${window.location.origin}/${path}` },
        });
      } else {
        setIsLoggingIn(false);
      }
    }
  }, [props.user, props.from, isConfigurationLoaded]);

  return isLoggingIn ? (
    <FullPageLoader>Logging in...</FullPageLoader>
  ) : redirectPath !== '' ? (
    <Redirect to={redirectPath} />
  ) : (
    <></>
  );
};
