import {
  ENQUEUE_FILES,
  UPLOAD_FINISH,
  FILE_UPLOAD_START,
  FILE_UPLOAD_FINISH,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_FAIL,
} from '../actions/actionConstants';
import { ConflictingItem } from '../components/modals/resolveConflictsModal';

export enum Status {
  Waiting,
  Uploading,
  Success,
  Error,
}

export enum ErrorId {
  NoError,
  InvalidType,
  InvalidName,
  NameTooLong,
  SizeTooBig,
  UploadOperationFailed,
}

export enum ConflictActions {
  Keep = 'Keep',
  Replace = 'Replace',
  Upload = 'Upload',
}

export interface UploadingItem {
  id: string;
  file: File;
  parentInstanceId: string;
  status: Status;
  error: string;
  progress: number;
  actionType: ConflictActions;
  relatedInstance?: RelatedUploadingItem;
}

export interface RelatedUploadingItem {
  instanceId: string;
  accessUrl: string;
}

export interface UploadState {
  uploadingItems: UploadingItem[];
  isLoading: boolean;
  errorIds: ErrorId[];
}

export const initialState: UploadState = {
  uploadingItems: [],
  isLoading: false,
  errorIds: [],
};

const getUpdatedItems = (
  uploadingItems: UploadingItem[],
  id: string,
  status: Status,
  progress: number
) =>
  uploadingItems.map(uploadingItem => {
    return uploadingItem.id === id
      ? {
          ...uploadingItem,
          status: status,
          error: '',
          progress: progress,
        }
      : uploadingItem;
  });

export const UploadReducer = (
  state: UploadState = initialState,
  action: any
) => {
  switch (action.type) {
    case ENQUEUE_FILES: {
      const { files, parentInstanceId } = action.payload;
      const uploading: UploadingItem[] = files.map((file: ConflictingItem) => {
        return {
          id:
            file.file.name +
            Math.random()
              .toString(36)
              .substr(2, 7),
          file: file.file,
          parentInstanceId: parentInstanceId,
          status: Status.Waiting,
          error: '',
          progress: 0,
          actionType: file.actionType,
          relatedInstance: {
            accessUrl: file.accessUrl,
            instanceId: file.instanceId,
          },
        } as UploadingItem;
      });
      return {
        ...state,
        uploadingItems: [...state.uploadingItems, ...uploading],
        isLoading: true,
      };
    }
    case UPLOAD_FINISH:
      return {
        ...state,
        uploadingItems: [],
        isLoading: false,
        errorIds: [],
      };
    case FILE_UPLOAD_START:
      return {
        ...state,
        uploadingItems: getUpdatedItems(
          state.uploadingItems,
          action.payload,
          Status.Uploading,
          0
        ),
        errorIds: state.errorIds,
      };
    case FILE_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadingItems: getUpdatedItems(
          state.uploadingItems,
          action.payload.id,
          Status.Uploading,
          action.payload.progress
        ),
      };
    }
    case FILE_UPLOAD_FINISH:
      return {
        ...state,
        uploadingItems: getUpdatedItems(
          state.uploadingItems,
          action.payload,
          Status.Success,
          0
        ),
      };
    case FILE_UPLOAD_FAIL:
      return {
        ...state,
        uploadingItems: getUpdatedItems(
          state.uploadingItems,
          action.payload.id,
          Status.Error,
          0
        ),
        errorIds: [...state.errorIds, action.payload.errorId],
      };
    default:
      return state;
  }
};
