import { useState } from 'react';
import { InputStatus } from '@bentley/bwc-react/core';

export const useInputField = () => {
  return useInputFieldWithInitialValues('', '', true);
};

export const useOptionalInputField = () => {
  return useInputFieldWithInitialValues('', '', false);
};

const useInputFieldWithInitialValues = (
  intialStatus: InputStatus,
  initialErrorMessage: string,
  initialIsPrimaryDisabled: boolean
): [
  InputStatus,
  string,
  boolean,
  (status: InputStatus, errorMessage: string) => void,
  () => void,
  () => void
] => {
  const [status, setStatus] = useState<InputStatus>(intialStatus);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const [isPrimaryDisabled, setIsPrimaryDisabled] = useState<boolean>(
    initialIsPrimaryDisabled
  );

  const setInputFieldState = (status: InputStatus, errorMessage: string) => {
    setStatus(status);
    setErrorMessage(errorMessage);
    setIsPrimaryDisabled(status === 'error' || status === '');
  };
  const invalidateInputFieldState = () => {
    setStatus(intialStatus);
    setErrorMessage(initialErrorMessage);
    setIsPrimaryDisabled(initialIsPrimaryDisabled);
  };
  const invalidateCommentFieldState = () => {
    setStatus(intialStatus);
    setErrorMessage(initialErrorMessage);
    setIsPrimaryDisabled(initialIsPrimaryDisabled);
  };
  return [
    status,
    errorMessage,
    isPrimaryDisabled,
    setInputFieldState,
    invalidateInputFieldState,
    invalidateCommentFieldState,
  ];
};
