// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bwc-inputs-labeled-input > .input > .icon {
  height: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/modals/modalInputs.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE","sourcesContent":[".bwc-inputs-labeled-input > .input > .icon {\n  height: 20px; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
