import { store } from '../store/configureStore';
import { initializeBreadcrumbs } from '../actions/breadcrumbsActions';
import { Item } from '../entities/entities';
import { setSelection } from '../actions/selectionActions';
import i18n from '../i18n';
import {
  hasItemWritePermissions,
  hasItemReadPermissions,
} from './permissionsService';

export const getFileTypeByExtension = (extension: string): string => {
  var type: string = '';

  switch (extension.toLowerCase()) {
    case 'anim':
      type = 'Anim';
      break;
    case 'amim':
      type = 'Amim';
      break;
    case 'dgn.i.dgn':
    case 'i.dgn':
    case 'i-model':
    case 'imodel':
      type = 'i-model';
      break;
    case 'bim':
      type = 'Bim';
      break;
    case 'dgndb':
      type = 'DgnDb';
      break;
    case 'dgn-db':
      type = 'Dgn-Db';
      break;
    case 'txt':
      type = 'Text';
      break;
    case 'png':
    case 'jpg':
    case 'bmp':
    case 'gif':
    case 'tif':
      type = 'Image';
      break;
    case 'dwg':
    case 'dxf':
    case 'skp':
    case 'obj':
    case 'cad':
    case '3ds':
    case 'fbx':
      type = 'AutoCad';
      break;
    case 'mism':
      type = 'Mism';
      break;
    case 'pdf':
      type = 'Pdf';
      break;
    case 'pptx':
    case 'pptm':
    case 'potx':
    case 'potm':
    case 'pot':
    case 'pps':
    case 'ppsx':
    case 'ppsm':
    case 'ppam':
    case 'ppa':
    case 'ppt':
      type = 'PowerPoint';
      break;
    case 'vsd':
      type = 'Visio';
      break;
    case 'avi':
    case 'amv':
    case 'mp4':
    case 'wmv':
      type = 'Video';
      break;
    case 'doc':
    case 'dot':
    case 'wbk':
    case 'docx':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
      type = 'Word';
      break;
    case 'xls':
    case 'xlt':
    case 'xlm':
    case 'xlsx':
    case 'xlsm':
    case 'xltx':
    case 'xltm':
    case 'csv':
      type = 'Excel';
      break;
    case 'xml':
    case 'html':
    case 'htm':
      type = 'Document';
      break;
    case 'rvt':
    case 'rfa':
      type = 'Revit';
      break;
    case 'rcnx':
    case 'retx':
      type = 'RAM';
      break;
    case 'std':
      type = 'Staad';
      break;
    case 'zip':
    case 'rar':
    case 'arj':
    case 'tar':
    case 'tgz':
    case '7z':
      type = 'Compressed';
      break;
    case 'dgn':
      type = 'MicroStation';
      break;
    case 'xfdf':
      type = 'Markup';
      break;
    case 'log':
      type = 'Log';
      break;
    case 'msg':
      type = 'Message';
      break;
    case 'vue':
      type = 'Vue';
      break;
    case 'ifc':
      type = 'Ifc';
      break;
    case 'shp':
    case 'geodb':
    case 'geojson':
    case 'shp.xml':
    case 'kml':
      type = 'Geo';
      break;
    case 'otxml':
      type = 'Open Tower';
      break;
    case 'nwc':
    case 'nwd':
      type = 'Navisworks';
      break;
    case 'json':
      type = 'JSON';
      break;
    case '3dm':
      type = 'Rhinoceros';
      break;
    default:
      type = 'Unknown';
      break;
  }

  return type;
};

export const getFileExtension = (name: string): string => {
  var nameParts: string[] = name.split('.');
  if (nameParts.length > 1) {
    return !['dgn', 'xml'].includes(
      nameParts[nameParts.length - 1].toLowerCase()
    )
      ? nameParts[nameParts.length - 1]
      : !['i', 'shp'].includes(nameParts[nameParts.length - 2].toLowerCase())
      ? nameParts[nameParts.length - 1]
      : nameParts[nameParts.length - 2].toLowerCase() == 'shp'
      ? 'shp.xml'
      : nameParts[nameParts.length - 3].toLowerCase() != 'dgn'
      ? 'i.dgn'
      : 'dgn.i.dgn';
  } else {
    return '';
  }
};

export const getNameWithoutExtension = (name: string): string => {
  const extension = getFileExtension(name);
  return extension === ''
    ? name
    : name.substring(0, name.lastIndexOf('.' + extension));
};
export const getFileType = (name: string): string =>
  getFileTypeByExtension(getFileExtension(name).toLowerCase());

export const getOfficeToolTip = (item: any): string => {
  const fileType = getFileType(item.name);
  if (fileType === 'Excel' || fileType == 'Word' || fileType == 'PowerPoint') {
    if (!store.getState().features.isWopiEnabled)
      return i18n.t(
        'Editing files online is no longer available. Please download the file, make your changes, and re-upload it.'
      );
    if (hasItemWritePermissions(item.accessOrigin))
      return i18n.t('Click here to view or edit in Office 365');
    if (hasItemReadPermissions(item.accessOrigin))
      return i18n.t('Click here to view in Office 365');
  }
  return item.name;
};

export const getProjectShareBaseUrl = () => {
  return `${
    store.getState().urls.projectShareUrl
  }/v2.4/repositories/BentleyCONNECT.ProjectShareV2--${
    store.getState().navigation.projectId
  }`;
};

export const getProjectShareUrl = () => {
  return `${
    store.getState().urls.projectShareUrl
  }/v2.4/repositories/BentleyCONNECT.ProjectShareV2--${
    store.getState().navigation.projectId
  }/ProjectShare`;
};

export const getRbacUrl = () => {
  return `${
    store.getState().urls.rbacUrl
  }/v2.4/Repositories/BentleyCONNECT--Main/RBAC`;
};

export const setBreadcrumbsFromItemPath = (items?: Item[]) => {
  const breadcrumbIds: string[] = [store.getState().navigation.projectId];
  const breadcrumbLabels: string[] = [
    store.getState().breadcrumbs.rootFolderName,
  ];
  if (items) {
    const item = items[0];
    const selection = items.map((i: Item) => i.instanceId);
    const parentLabels = item.path.split('/');
    for (let i = 1; i < parentLabels.length - 1; i++) {
      breadcrumbLabels.push(parentLabels[i]);
      breadcrumbIds.push('');
    }

    if (item.className == 'File') {
      breadcrumbIds[breadcrumbIds.length - 1] = item.parentFolderId;
      if (!store.getState().data.isSearch) {
        store.dispatch(setSelection(selection));
      } else if (
        store.getState().selection.selectedItems[0] &&
        store.getState().selection.selectedItems[0].instanceId !==
          item.instanceId
      ) {
        store.dispatch(setSelection(selection));
      }
    } else {
      breadcrumbIds.push(item.instanceId);
      breadcrumbLabels.push(item.name);
    }
  }
  store.dispatch(initializeBreadcrumbs(breadcrumbIds, breadcrumbLabels));
};

export const openFileInNewWindow = (
  path: string,
  type: string,
  window: any,
  fileName: string
) => {
  window.document.head.innerHTML = '<title>' + fileName + '</title>';
  switch (type.toLowerCase()) {
    case 'video': {
      window.document.body.innerHTML =
        `<video  width='100%' height= '100%' controls > <source src= '` +
        path +
        `'  type= 'video/mp4'>  `;
      break;
    }
    case 'image': {
      window.document.body.innerHTML = `<img src='` + path + `' >`;
      break;
    }
    default: {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', path, true);
      xmlhttp.setRequestHeader('Content-type', 'application/json');
      xmlhttp.onload = (res: any) => {
        var escape = document.createElement('textarea');
        escape.textContent = res['target']['response'];
        var txt = escape.innerHTML;
        txt = txt.replace(/ /g, '&nbsp;');
        txt = txt.replace(/\n/g, '<br>');
        window.document.body.innerHTML = txt;
      };
      xmlhttp.send();
    }
  }
};

export const itemNameSortFunction = (
  a: Item,
  b: Item,
  descending: boolean = true
) => {
  const aIsFolder = a.contentType == 'Folder';
  const bIsFolder = b.contentType == 'Folder';
  const bothAreFolders = aIsFolder && bIsFolder;

  const putAFirstValue = descending ? 1 : -1;
  const putBFirstValue = descending ? -1 : 1;

  if (bothAreFolders) {
    return a.name.localeCompare(b.name);
  }
  if (aIsFolder) {
    return putAFirstValue;
  }
  if (bIsFolder) {
    return putBFirstValue;
  }

  return a.name.localeCompare(b.name);
};

export const itemSizeSortFunction = (a: string | null, b: string | null) => {
  const aIsFolder = a == null;
  const bIsFolder = b == null;
  const bothAreFolders = aIsFolder && bIsFolder;

  let aSize = Number(a);
  let bSize = Number(b);
  if (bothAreFolders) {
    return 0;
  }
  if (aIsFolder) {
    aSize = 0;
  }
  if (bIsFolder) {
    bSize = 0;
  }

  return aSize - bSize;
};
