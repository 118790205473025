// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-version-button {
  flex: 0 0 auto;
  align-self: flex-end;
  margin-top: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/informationPanel/detailInfo.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB,EAAE","sourcesContent":[".view-version-button {\n  flex: 0 0 auto;\n  align-self: flex-end;\n  margin-top: 20px; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
